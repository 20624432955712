// src/components/pages/PrivacyPolicy.js

import React from 'react';
import LegalPage from './LegalPage';

export default function SecurityPolicy() {
    return (
        <div>
            <LegalPage pageType="security_policy" />
        </div>
    );
}
