import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types'; // Для проверки типов пропсов
import InputWithLabel from '../Form/Input/InputWithLabel';
import api from '../../api';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals

const EditServicesPopup = ({ show, onClose, onSave, vendorId }) => {
    const [formData, setFormData] = useState({
        services: [] // Изначально пустой список услуг
    });

    // Состояние для хранения ошибок валидации
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // Загружаем услуги конкретного вендора
        const fetchVendorData = async () => {
            try {
                const vendorServicesResponse = await api.get(`/vendor/${vendorId}/service-subtypes/`); // Услуги вендора
                setFormData({
                    services: vendorServicesResponse.data || [] // Устанавливаем услуги вендора
                });
            } catch (error) {
                console.error('Error fetching vendor service subtypes:', error);
            }
        };

        if (vendorId) {
            fetchVendorData(); // Загружаем данные, если есть ID вендора
        }
    }, [vendorId]);

    const handleServiceChange = (index, e) => {
        const { name, value } = e.target;
        const updatedServices = formData.services.map((service, i) => {
            if (i === index) {
                return { ...service, [name]: value }; // Обновляем name
            }
            return service;
        });

        setFormData((prevData) => ({
            ...prevData,
            services: updatedServices
        }));

        // Очистка ошибок для конкретного поля при изменении
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (newErrors.services && newErrors.services[index]) {
                newErrors.services[index][name] = '';
                // Удаляем объект ошибок, если в нём больше нет ошибок
                if (Object.keys(newErrors.services[index]).length === 0) {
                    delete newErrors.services[index];
                }
            }
            return newErrors;
        });
    };

    const handleAddServiceSelect = () => {
        setFormData((prevData) => ({
            ...prevData,
            services: [
                ...prevData.services,
                { subtype_id: '', name: '', service_type: prevData.services[0]?.service_type || '' }
            ] // Добавляем новую услугу с пустыми данными и корректным service_type
        }));
    };

    const handleDeleteService = (index) => {
        const updatedServices = formData.services.filter((_, i) => i !== index);

        setFormData((prevData) => ({
            ...prevData,
            services: updatedServices
        }));

        // Удаляем связанные ошибки при удалении услуги
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (newErrors.services) {
                newErrors.services.splice(index, 1);
                if (newErrors.services.length === 0) {
                    delete newErrors.services;
                }
            }
            return newErrors;
        });
    };

    // Функция валидации формы
    const validateForm = useCallback(() => {
        const newErrors = {};

        formData.services.forEach((service, index) => {
            const serviceErrors = {};

            // Проверка, что поле name не пустое
            if (!service.name || service.name.trim() === '') {
                serviceErrors.name = 'Service name is required.';
            }

            if (Object.keys(serviceErrors).length > 0) {
                if (!newErrors.services) {
                    newErrors.services = [];
                }
                newErrors.services[index] = serviceErrors;
            }
        });

        setErrors(newErrors);

        // Возвращаем true, если нет ошибок
        return Object.keys(newErrors).length === 0;
    }, [formData.services]);

    // Обработка отправки формы с валидацией
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const finalData = formData.services.map(service => ({
            subtype_id: service.subtype_id || null, // Передаем id если есть, иначе null
            name: service.name, // Передаем name, чтобы его можно было обновить
            service_type: service.service_type || null // Передаем service_type только если он есть
        }));
        onSave(finalData); // Передача данных обратно
    };

    if (!show) return null;

    return ReactDOM.createPortal(
        <div className="modal visible" id="edit-services">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit}>
                        <h2>Edit Services</h2>
                        {formData.services.map((service, index) => (
                            <React.Fragment key={index}>
                                <InputWithLabel
                                    name="name"
                                    value={service.name} // Отображаем и редактируем name
                                    onChange={(e) => handleServiceChange(index, e)}
                                    label="Service Name"
                                    showDelete={true}
                                    onDelete={() => handleDeleteService(index)}
                                    error={errors.services?.[index]?.name} // Отображаем ошибку, если есть
                                />
                            </React.Fragment>
                        ))}

                        <button
                            className="underline no-style"
                            type="button"
                            onClick={handleAddServiceSelect}
                        >
                            Add New Service
                        </button>

                        <button className="button button-black" type="submit">Save Services</button>
                    </form>
                </div>
            </div>
        </div>,
        document.body // Портал в body
    );
};

// Определение типов пропсов для лучшей проверки
EditServicesPopup.propTypes = {
    show: PropTypes.bool.isRequired, // Флаг отображения попапа
    onClose: PropTypes.func.isRequired, // Функция для закрытия попапа
    onSave: PropTypes.func.isRequired, // Функция для обработки сохранённых данных
    vendorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // ID вендора
};

export default EditServicesPopup;
