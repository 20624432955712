import React, { useState, useEffect, useCallback } from 'react';
import InputSelectWithoutRadio from '../Form/Input/InputSelectWithoutRadio';
import api from '../../api';
import { useNotification } from '../../context/NotificationContext';
import moment from 'moment';

const ManageLegalCompaniesPopup = ({
    show,
    closeManageLegalCompaniesPopup,
    initialData,
    onSave,
}) => {
    const [formData, setFormData] = useState({
        connectedCompanies: initialData || [],
    });
    const [availableCompanies, setAvailableCompanies] = useState([]);
    const [availableToAddCompanies, setAvailableToAddCompanies] = useState([]);
    const { showNotification } = useNotification();

    useEffect(() => {
        if (initialData && initialData.length > 0) {
            const companiesWithFlag = initialData.map((company) => {
                // Получаем billing_period из активного плана компании
                const activeBillingPeriod = getCompanyBillingPeriod(company);

                return {
                    ...company,
                    isExisting: true,
                    billing_period: activeBillingPeriod || 'monthly',
                };
            });
            setFormData({
                connectedCompanies: companiesWithFlag,
            });
        }
    }, [initialData]);

    const getCompanyBillingPeriod = (company) => {
        if (company.active_plans && company.active_plans.length > 0) {
            const activeProPlan = company.active_plans.find(
                (planObj) =>
                    planObj.plan &&
                    planObj.plan.name === 'Pro' &&
                    planObj.subscription_status === 'active'
            );
            if (activeProPlan) {
                return activeProPlan.billing_period || 'monthly';
            }
        }
        return null;
    };

    const getActiveProPlan = (company) => {
        const currentDate = moment();

        if (!company.active_plans || company.active_plans.length === 0) {
            return null;
        }

        const activeProPlans = company.active_plans.filter((planObj) => {
            const plan = planObj.plan;
            const startDate = moment(
                planObj.start_date || planObj.current_period_start
            );
            const endDate = moment(
                planObj.end_date || planObj.current_period_end
            );

            return (
                plan.name === 'Pro' &&
                currentDate.isBetween(startDate, endDate, 'day', '[]') &&
                planObj.subscription_status === 'active'
            );
        });

        if (activeProPlans.length > 0) {
            return activeProPlans[0].plan;
        } else {
            return null;
        }
    };

    useEffect(() => {
        const fetchLegalCompanies = async () => {
            try {
                const response = await api.get('/legal_companies/active/');
                setAvailableCompanies(response.data);

                if (!initialData || initialData.length === 0) {
                    const companiesWithFlag = response.data.map((company) => ({
                        ...company,
                        isExisting: true,
                        billing_period: getCompanyBillingPeriod(company) || 'monthly',
                    }));
                    setFormData({ connectedCompanies: companiesWithFlag });
                }
            } catch (error) {
                console.error('Error fetching legal companies:', error);
                showNotification('Failed to load legal companies.', 'error');
            }
        };

        fetchLegalCompanies();
    }, [showNotification, initialData]);

    const fetchAvailableToAddCompanies = useCallback(async () => {
        try {
            const response = await api.get(
                '/user/available_legal_companies/active/'
            );

            if (response.data.length === 0) {
                showNotification('No available companies to add.', 'info');
                return false;
            }

            setAvailableToAddCompanies(response.data);
            return true;
        } catch (error) {
            console.error(
                'Error fetching available to add legal companies:',
                error
            );
            showNotification(
                'Failed to load available legal companies for adding.',
                'error'
            );
            return false;
        }
    }, [showNotification]);

    const handleCompanyChange = useCallback(
        (index, e) => {
            const selectedCompanyId = e.target.value;
            const selectedCompany =
                availableCompanies.find(
                    (company) => company.id.toString() === selectedCompanyId
                ) ||
                availableToAddCompanies.find(
                    (company) => company.id.toString() === selectedCompanyId
                );
            const selectedCompanyObject = selectedCompany;

            if (selectedCompanyObject) {
                const activeBillingPeriod = getCompanyBillingPeriod(
                    selectedCompanyObject
                );

                setFormData((prevData) => {
                    const updatedCompanies = [...prevData.connectedCompanies];
                    const existingCompany = updatedCompanies[index];
                    updatedCompanies[index] = {
                        ...selectedCompanyObject,
                        isExisting: existingCompany.isExisting,
                        billing_period:
                            activeBillingPeriod ||
                            existingCompany.billing_period ||
                            'monthly',
                    };

                    return { connectedCompanies: updatedCompanies };
                });
            } else {
            }
        },
        [availableCompanies, availableToAddCompanies]
    );

    const handleAddCompany = async () => {

        // Проверяем, добавлена ли уже новая компания (isExisting: false)
        const hasNewCompany = formData.connectedCompanies.some(
            (company) => !company.isExisting
        );
        if (hasNewCompany) {
            showNotification('You can add only one new company.', 'warning');
            return;
        }

        const companiesAvailable = await fetchAvailableToAddCompanies();

        if (!companiesAvailable) {
            return;
        }

        setFormData((prevData) => ({
            connectedCompanies: [
                ...prevData.connectedCompanies,
                {
                    id: '',
                    name: '',
                    isExisting: false,
                    billing_period: 'monthly', // Устанавливаем billing_period по умолчанию
                },
            ],
        }));
    };

    const handleDeleteCompany = (index) => {
        if (
            window.confirm('Are you sure you want to delete this company?')
        ) {
            setFormData((prevData) => {
                const companyToRemove = prevData.connectedCompanies[index];
                const updatedCompanies = prevData.connectedCompanies.filter(
                    (_, i) => i !== index
                );
                return { connectedCompanies: updatedCompanies };
            });
            showNotification('Company successfully deleted.', 'success');
        } else {
        }
    };

    const handleBillingPeriodChange = (index, newBillingPeriod) => {
        setFormData((prevData) => {
            const updatedCompanies = [...prevData.connectedCompanies];
            updatedCompanies[index] = {
                ...updatedCompanies[index],
                billing_period: newBillingPeriod,
            };
            return { connectedCompanies: updatedCompanies };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const companiesWithNoActivePlan = formData.connectedCompanies
            .filter((company) => company.id)
            .filter((company) => !getActiveProPlan(company));

        companiesWithNoActivePlan.forEach((company, index) => {
        });

        const finalData = companiesWithNoActivePlan.map((company) => ({
            company_id: company.id,
            billing_period: company.billing_period || 'monthly',
        }));


        if (finalData.length === 0) {
            showNotification(
                'All selected companies already have active Pro plans.',
                'info'
            );
            return;
        }

        try {
            const response = await api.post('/pro/legal_companies/update/', {
                companies: finalData,
            });

            const { checkout_sessions } = response.data;

            if (checkout_sessions && checkout_sessions.length > 0) {
                // Перенаправляем пользователя на первую сессию
                window.location.href = checkout_sessions[0].checkout_url;
            }
        } catch (error) {
            console.error(
                'handleSubmit: Ошибка при сохранении юридических компаний:',
                error
            );
            showNotification('Error saving legal companies.', 'error');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    if (!show) {
        return null;
    }

    // Проверяем, добавлена ли уже одна новая компания
    const hasNewCompany = formData.connectedCompanies.some(
        (company) => !company.isExisting
    );

    return (
        <div className="modal visible" id="manage-legal-companies">
            <div
                className="modal__overlay"
                onClick={closeManageLegalCompaniesPopup}
            ></div>
            <div className="modal__content">
                <button
                    onClick={closeManageLegalCompaniesPopup}
                    className="button no-style close js--close-modal"
                >
                    <img
                        src="/assets/icon--close-CN6QlgAA.svg"
                        alt="Close"
                    />
                </button>
                <div className="modal__form">
                    <form
                        className="content"
                        onSubmit={handleSubmit}
                        onKeyDown={handleKeyDown}
                    >
                        <h2>Manage Pro Accounts</h2>

                        {formData.connectedCompanies.length > 0 ? (
                            formData.connectedCompanies.map(
                                (company, index) => {
                                    const isConnected = company.isExisting;
                                    const options = isConnected
                                        ? availableCompanies.map((comp) => ({
                                              value: comp.id,
                                              label: comp.name,
                                          }))
                                        : availableToAddCompanies.map(
                                              (comp) => ({
                                                  value: comp.id,
                                                  label: comp.name,
                                              })
                                          );

                                    return (
                                        <React.Fragment
                                            key={company.id || index}
                                        >
                                            <InputSelectWithoutRadio
                                                name={`company-${index}`}
                                                value={company.id || ''}
                                                onChange={(e) =>
                                                    handleCompanyChange(
                                                        index,
                                                        e
                                                    )
                                                }
                                                label="Select a Company"
                                                options={options}
                                                showDelete={true}
                                                onDelete={() =>
                                                    handleDeleteCompany(index)
                                                }
                                                error={null}
                                                disabled={isConnected}
                                                company={company}
                                                billingPeriod={
                                                    company.billing_period
                                                }
                                                onBillingPeriodChange={(
                                                    newBillingPeriod
                                                ) =>
                                                    handleBillingPeriodChange(
                                                        index,
                                                        newBillingPeriod
                                                    )
                                                }
                                            />
                                        </React.Fragment>
                                    );
                                }
                            )
                        ) : (
                            <p>No connected companies.</p>
                        )}

                        {!hasNewCompany && (
                            <button
                                className="underline no-style"
                                type="button"
                                onClick={handleAddCompany}
                            >
                                Add New Company
                            </button>
                        )}

                        <button
                            className="button button-black create-profile-button"
                            type="submit"
                            disabled={false}
                        >
                            Submit To Connect
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ManageLegalCompaniesPopup;
