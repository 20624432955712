// src/components/Notification.js

import React from 'react';
import { useNotification } from '../context/NotificationContext';
// import './Notification.css'; // Создайте соответствующий CSS файл

const Notification = () => {
    const {
        notification,
        notificationDetails,
        notificationStrong,
        notificationType,
        hideNotification
    } = useNotification();

    if (!notification) return null;

    return (
        <div
            className={`header__notification ${notificationType}`} // Добавляем тип уведомления
            onClick={hideNotification}
            role="alert"
            aria-live="assertive"
        >
            <div className="container">
                <div className="content">
                    <div className="popup-notofication">
                        {/* Меняем иконку в зависимости от типа уведомления */}
                        {notificationType === 'success' && <img src="/assets/icon--success.svg" alt="Success Icon" />}
                        {notificationType === 'error' && <img src="/assets/icon--error.svg" alt="Error Icon" />}
                        {notificationType === 'info' && <img src="/assets/icon--info.svg" alt="Info Icon" />}
                        {notificationType === 'warning' && <img src="/assets/icon--warning.svg" alt="Warning Icon" />}
                        <span>{notification}</span>
                    </div>
                    {notificationDetails && <img src="/assets/icon--note-BZ4B64tE.svg" alt="Note Icon" />}
                    <span className="text">
                        {notificationDetails && <span>{notificationDetails} </span>}
                        {notificationStrong && <strong>{notificationStrong}</strong>}
                    </span>
                    {/*<button className="notification-close-button" onClick={hideNotification}>*/}
                    {/*    &times;*/}
                    {/*</button>*/}
                </div>
            </div>
        </div>
    );
};

export default Notification;
