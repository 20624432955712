// src/utils/validateImage.js

export const validateImage = (file, minWidth, minHeight, expectedRatio, tolerance) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
            const width = img.width;
            const height = img.height;
            const actualRatio = width / height;

            // Проверка минимальных размеров
            if (width < minWidth || height < minHeight) {
                URL.revokeObjectURL(objectUrl);
                reject(`Image is too small. Minimum size is ${minWidth}x${minHeight} pixels.`);
                return;
            }

            // Проверка соотношения сторон
            if (!(expectedRatio - tolerance <= actualRatio && actualRatio <= expectedRatio + tolerance)) {
                URL.revokeObjectURL(objectUrl);
                reject(`Invalid aspect ratio. Expected approximately ${expectedRatio}:1 (min. size: ${minWidth}x${minHeight} pixels). `);
                return;
            }

            URL.revokeObjectURL(objectUrl);
            resolve();
        };

        img.onerror = () => {
            URL.revokeObjectURL(objectUrl);
            reject('Invalid image file.');
        };

        img.src = objectUrl;
    });
};
