import React from "react";
import './Footer.css';

export default function Footer() {
    return (
        <div className='profile__block'>
            <div className='container'>
                <footer className='footer'>
                    <div className='footer__social'>
                        <h2>Follow Us in Social Media</h2>
                        <ul className="social">
                            <li>
                                <a
                                    title="Instagram"
                                    data-testid="SocialIcon"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://instagram.com/law.law.us"
                                    className="[&img]:pointer-events-none z-0 p-2 transition-transform hover:scale-[107.5%]"
                                >
                                    <img
                                        src="/assets/instagram.png"
                                        alt="Instagram"
                                    />
                                </a>
                            </li>

                            <li>
                                <a
                                    title="X"
                                    data-testid="SocialIcon"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://x.com/law_law_us"
                                    aria-label="X"
                                    className="[&img]:pointer-events-none z-0 p-2 transition-transform hover:scale-[107.5%]"
                                >
                                    <img
                                        src="/assets/twitter.png" // Замените на путь к вашей иконке X
                                        alt="X"
                                    />
                                </a>
                            </li>

                            <li>
                                <a
                                    title="YouTube"
                                    data-testid="SocialIcon"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.youtube.com/@law.law.youtube/featured"
                                    aria-label="YouTube"
                                    className="[&img]:pointer-events-none z-0 p-2 transition-transform hover:scale-[107.5%]"
                                >
                                    <img
                                        src="/assets/youtube.png" // Замените на путь к вашей иконке YouTube
                                        alt="YouTube"
                                    />
                                </a>
                            </li>
                            <li>
                                <a
                                    title="Linktree"
                                    data-testid="SocialIcon"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://linktr.ee/law.law"
                                    aria-label="Linktree"
                                    className="[&img]:pointer-events-none z-0 p-2 transition-transform hover:scale-[107.5%]"
                                >
                                    <img
                                        src="/assets/linktree.png"
                                        alt="Linktree"
                                    />
                                </a>
                            </li>
                            {/*<li>*/}
                            {/*    <a*/}
                            {/*        title="Email"*/}
                            {/*        data-testid="SocialIcon"*/}
                            {/*        target="_blank"*/}
                            {/*        rel="noopener noreferrer"*/}
                            {/*        href="mailto:law.usa.law@gmail.com"*/}
                            {/*        aria-label="Email"*/}
                            {/*        className="[&img]:pointer-events-none z-0 p-2 transition-transform hover:scale-[107.5%]"*/}
                            {/*    >*/}
                            {/*        <img*/}
                            {/*            src="/assets/email.png" // Замените на путь к вашей иконке Email*/}
                            {/*            alt="Email"*/}
                            {/*        />*/}
                            {/*    </a>*/}
                            {/*</li>*/}

                            <li>
                                <a
                                    title="Facebook"
                                    data-testid="SocialIcon"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.facebook.com/profile.php?id=61563077785826&is_tour_dismissed"
                                    aria-label="Facebook"
                                    className="[&img]:pointer-events-none z-0 p-2 transition-transform hover:scale-[107.5%]"
                                >
                                    <img
                                        src="/assets/facebook.png" // Замените на путь к вашей иконке Facebook
                                        alt="Facebook"
                                    />
                                </a>
                            </li>

                            <li>
                                <a
                                    title="TikTok"
                                    data-testid="SocialIcon"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://tiktok.com/@law.law.usa"
                                    aria-label="TikTok"
                                    className="[&img]:pointer-events-none z-0 p-2 transition-transform hover:scale-[107.5%]"
                                >
                                    <img
                                        src="/assets/tiktok.png" // Замените на путь к вашей иконке TikTok
                                        alt="TikTok"
                                    />
                                </a>
                            </li>

                            <li>
                                <a
                                    title="LinkedIn"
                                    data-testid="SocialIcon"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.linkedin.com/company/law-law-us/about/?viewAsMember=true"
                                    aria-label="LinkedIn"
                                    className="[&img]:pointer-events-none z-0 p-2 transition-transform hover:scale-[107.5%]"
                                >
                                    <img
                                        src="/assets/linkedin.png" // Замените на путь к вашей иконке LinkedIn
                                        alt="LinkedIn"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer__separator'></div>

                    <div className='footer__bottom'>
                        <img src='/assets/logo--footer.svg' className='footer__logo' alt='Logo'/>
                        <span className='footer__copyright'>
                                    &copy;&nbsp;AI Revolution LLC, 2024 . All rights reserved
                                </span>

                        <nav className='footer__nav'>
                            <ul>
                                <li>
                                    <a href='/privacy-policy'>Privacy Policy</a>
                                </li>
                                <li>
                                    <a href='/cookie-policy'>Cookie Policy</a>
                                </li>
                                <li>
                                    <a href='/terms-of-use'>Terms Of Use</a>
                                </li>
                                <li>
                                    <a href='/attorney-adv-rules'>Attorney Advertising Rules</a>
                                </li>
                                <li>
                                    <a href='/dmca-policy'>DMCA Policy</a>
                                </li>

                                <li>
                                    <a href="//www.dmca.com/Protection/Status.aspx?ID=cafa144c-7515-4779-9564-f2b74029a9fa"
                                       title="DMCA.com Protection Status" className="dmca-badge"> <img
                                        src="https://images.dmca.com/Badges/dmca-badge-w150-5x1-01.png?ID=cafa144c-7515-4779-9564-f2b74029a9fa"
                                        alt="DMCA.com Protection Status"/></a>
                                    <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </footer>
            </div>
        </div>
    )
}