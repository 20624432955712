// src/components/pages/DMCAPolicy.js

import React from 'react';
import LegalPage from './LegalPage';

export default function AttorneyAdvRules() {
    return (
        <div>
            <LegalPage pageType="attorney_adv_rules" />
        </div>
    );
}
