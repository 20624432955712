import React, {useState, useEffect, useCallback} from 'react';
import {MultiSelect} from 'primereact/multiselect';
import api from '../../api';
import './style.css';

const MultiSelectPlatformsAndContactMethods = ({label, onChange}) => {
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    // Загружаем опции из API
    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const [platformsResponse, contactMethodsResponse] = await Promise.all([
                    api.get('/platforms/'),
                    api.get('/contact-methods/')
                ]);

                const platforms = platformsResponse.data.map(item => ({
                    id: item.platform_id,
                    name: item.name,
                    value: item.name,  // Используем `name` для платформ как `value`
                    type: 'Platform'
                }));

                const contactMethods = contactMethodsResponse.data.map(item => ({
                    id: item.contact_method_id || item.id,
                    name: item.name,  // Отображаем `name`
                    value: item.value || item.name,  // Используем `value` или `name` для отправки
                    type: 'Contact Method'
                }));

                setOptions([...platforms, ...contactMethods]);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
    }, []);

    // Обрабатываем изменение выбранных опций
    const handleChange = useCallback((selectedOptions) => {
        setSelectedOptions(selectedOptions);

        if (onChange) {
            // Добавляем тип `Platform` к строковым опциям
            const updatedOptions = selectedOptions.map(option => {
                if (typeof option === 'string') {
                    return {name: option, value: option, type: 'Platform'};
                }
                return option;
            });

            // Фильтрация контактных методов
            const contactMethods = updatedOptions
                .filter(option => option.type === 'Contact Method' || Array.isArray(option))
                .map(method => {
                    if (Array.isArray(method)) {
                        return method; // Возвращаем массив как есть
                    }
                    return method.value || method.name; // Если это объект, возвращаем его значение
                });

            // Фильтрация платформ (социальные медиа)
            const socialMedia = updatedOptions
                .filter(option => option.type === 'Platform')
                .map(platform => platform.value || platform.name);  // Возвращаем строку


            // Отправляем отфильтрованные данные, удаляя тип `Platform`
            onChange({
                contactMethods: contactMethods.filter(method => method !== null),
                socialMedia: socialMedia.filter(platform => platform !== null)
            });
        }
    }, [onChange]);


    return (
        <div className="column">
            <label className="label">{label}</label>
            <MultiSelect
                value={selectedOptions}
                options={options}
                onChange={(e) => handleChange(e.value)}
                optionLabel="name"  // Отображаем названия из `name`
                display="chip"
                filter
                className=""
                placeholder=""
            />
        </div>
    );
};

export default MultiSelectPlatformsAndContactMethods;