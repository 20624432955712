// src/components/User/FacebookButton.js

import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { useNotification } from '../../context/NotificationContext';
import { useUser } from '../../context/UserContext';
import Loader from "../Loader";

const FacebookButton = ({ redirectPath }) => {
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();
    const { setAuthTokens, setCreateVendorFlag } = useUser();
    const navigate = useNavigate();

    const responseFacebook = async (response) => {
        setLoading(true);
        const access_token = response.accessToken;

        if (access_token) {
            try {
                // Отправляем access_token на бэкенд
                const backendResponse = await api.post('/auth/facebook/', {
                    access_token: access_token
                });

                if (backendResponse.status === 200) {
                    const tokens = {
                        access: backendResponse.data.access,
                        refresh: backendResponse.data.refresh
                    };
                    setAuthTokens(tokens);
                    localStorage.setItem('access_token', tokens.access);
                    localStorage.setItem('refresh_token', tokens.refresh);

                    // Если требуется установить флаг создания Vendor
                    if (redirectPath === '/sign-up') {
                        setCreateVendorFlag();
                    }

                    showNotification(
                        'Facebook authentication successful.',
                        'Welcome!',
                        null,
                        'success'
                    );

                    navigate(redirectPath);
                } else {
                    showNotification(
                        'Facebook authentication failed.',
                        'Please try again.',
                        null,
                        'error'
                    );
                }
            } catch (error) {
                console.error('Facebook Sign-Up Error:', error);
                showNotification(
                    'Facebook authentication failed.',
                    'Please try again later.',
                    null,
                    'error'
                );
            } finally {
                setLoading(false);
            }
        } else {
            console.error('Invalid credential response:', response);
            showNotification(
                'Facebook authentication failed.',
                'Invalid credential response.',
                null,
                'error'
            );
            setLoading(false);
        }
    };

    const handleFailure = (error) => {
        console.error('Facebook Sign-Up Error:', error);
        showNotification(
            'Facebook authentication failed.',
            'Please try again later.',
            null,
            'error'
        );
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID} // Ваш Facebook App ID
            autoLoad={false}
            fields="first_name,last_name,email"
            callback={responseFacebook}
            onFailure={handleFailure}
            render={renderProps => (
                <button
                    className="button button-border"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    type="button"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <img src="/assets/Facebook.svg" alt="Facebook Icon" style={{ marginRight: '8px' }} />
                    Continue with Facebook
                </button>
            )}
        />
    );
};

export default FacebookButton;
