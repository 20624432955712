import React, {useState, useEffect} from 'react';
import {MultiSelect} from 'primereact/multiselect';
import api from '../../api';
import './style.css';

const MultiSelectWithDependentZipCodes = ({label, onChange, cities, isZipcodeProvided}) => {
    const [zipCodes, setZipCodes] = useState([]);
    const [selectedZipCodes, setSelectedZipCodes] = useState([]);

    useEffect(() => {
        const fetchZipCodes = async () => {
            if (cities.length === 0 || isZipcodeProvided) {
                setZipCodes([]);
                setSelectedZipCodes([]);
                return;
            }

            try {
                const cityIds = cities.map(city => city.city_id).join(',');
                const response = await api.get(`/zipcodes-for-multiselect/?cities=${cityIds}`);
                const fetchedZipCodes = response.data.map(zip => ({name: zip.zipcode, code: zip.zipcode}));
                setZipCodes(fetchedZipCodes);

                // Автоматический выбор всех zip-кодов
                setSelectedZipCodes(fetchedZipCodes);

            } catch (error) {
                console.error('Error fetching zip codes:', error);
            }
        };

        fetchZipCodes();
    }, [cities, isZipcodeProvided]);

    useEffect(() => {
        if (onChange) {
            onChange(selectedZipCodes);
        }
    }, [selectedZipCodes, onChange]);

    return (
        <div className="column">
            <label className="label">{label}</label>
            <MultiSelect
                value={selectedZipCodes}
                options={zipCodes}
                onChange={(e) => setSelectedZipCodes(e.value)}
                optionLabel="name"
                display="chip"
                filter
                className=""
                placeholder={isZipcodeProvided ? "Near Me Location Detected" : "Select cities at the beginning"}
                disabled={cities.length === 0 || isZipcodeProvided} // Отключаем, если ZIP-код был предоставлен
            />
        </div>
    );
};

export default MultiSelectWithDependentZipCodes;
