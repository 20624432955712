import React, {useCallback, useContext, useEffect, useState} from 'react';
import HeaderBanner from './HeaderBanner';
import HeaderMenu from './HeaderMenu';
import HeaderDescription from './HeaderDescription';
import HeaderSearch from './HeaderSearch';
import HeaderIndexVendorsTitle from "./HeaderIndexVendorsTitle";
import {SearchContext} from "../../context/SearchContext";

const baseUrlForHeader = process.env.REACT_APP_BASE_URL_HEADER;

const Header = ({onSearch, isIndexPage, showFeedbackPopup, showSharePopup, isPopupActive, user}) => {
    const {searchSended} = useContext(SearchContext);
    const [isBannerMinimized, setIsBannerMinimized] = useState(window.location.href !== baseUrlForHeader);
    const [isPageMinimized, setIsPageMinimized] = useState(false);
    const [scrollOffset, setScrollOffset] = useState(window.scrollY === 0);
    const [isScrollingDown, setIsScrollingDown] = useState(false);
    const [secondScroll, setSecondScroll] = useState(false);
    const [allowHeaderMinimize, setAllowHeaderMinimize] = useState(false);

    const isBioPage = window.location.pathname.includes('bio');
    const isLawFirmsPage = window.location.pathname.includes('law-firms');
    const isVendorsPage = window.location.pathname.includes('vendors');
    const isSignInPage = window.location.pathname.includes('sign-in');
    const isSignUpPage = window.location.pathname.includes('sign-up');
    const isForgotPass = window.location.pathname.includes('forgot-password');
    const isResetPass = window.location.pathname.includes('reset-password');
    const isProfilePage = window.location.pathname.includes('profile');
    const isResendPage = window.location.pathname.includes('resend-activation-email');
    const isActivatePage = window.location.pathname.includes('activate');
    const isPrivacyPolicyPage = window.location.pathname.includes('privacy-policy');
    const isTermsOfUsePage = window.location.pathname.includes('terms-of-use');
    const isCookiePolicyPage = window.location.pathname.includes('cookie-policy');
    const isDMCAPolicyPage = window.location.pathname.includes('dmca-policy');
    const isAttorneyRulesPage = window.location.pathname.includes('attorney-adv-rules');
    const isHomePage = window.location.pathname === '/';
    const hasCompanyIdOrAttorneyId = window.location.search.includes('companyId') ||
        window.location.search.includes('attorneyId') ||
        window.location.search.includes('vendorId');

    useEffect(() => {
        const handleWindowScroll = () => {
            const scrollY = window.scrollY;
            const newScrollOffset = scrollY === 0;

            if (newScrollOffset !== scrollOffset) {
                setScrollOffset(newScrollOffset);
            }

            if (!isBannerMinimized && scrollY > 0) {
                setIsScrollingDown(true);
                minimizeBanner();
            } else if (isBannerMinimized && !isPageMinimized && (allowHeaderMinimize || scrollY > 10000)) {
                setIsScrollingDown(true);
                minimizeHeader();
            }
        };

        if (!hasCompanyIdOrAttorneyId) {
            window.addEventListener('scroll', handleWindowScroll);
        } else {
            minimizeBanner();
            minimizeHeader();
        }

        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, [isPageMinimized, isBannerMinimized, scrollOffset, allowHeaderMinimize, hasCompanyIdOrAttorneyId]);

    const handleWindowKeydown = useCallback((e) => {
        if (!isScrollingDown && (e.key === 'PageDown' || e.key === 'ArrowDown')) {
            e.preventDefault();
            if (!isBannerMinimized) {
                minimizeBanner();
            } else if (!isPageMinimized) {
                if (secondScroll && allowHeaderMinimize) {
                    minimizeHeader();
                } else {
                    setSecondScroll(true);
                }
            }
        }
    }, [isPageMinimized, isBannerMinimized, isScrollingDown, secondScroll, allowHeaderMinimize]);

    useEffect(() => {
        if (!hasCompanyIdOrAttorneyId) {
            document.addEventListener('keydown', handleWindowKeydown);
        }

        return () => {
            document.removeEventListener('keydown', handleWindowKeydown);
        };
    }, [handleWindowKeydown, hasCompanyIdOrAttorneyId]);

    const minimizeBanner = useCallback(() => {
        document.body.style.overflow = 'hidden';
        setIsBannerMinimized(true);

        setTimeout(() => {
            setIsScrollingDown(false);
            document.body.style.overflow = '';
            setAllowHeaderMinimize(true);
        }, 650);
    }, []);

    const minimizeHeader = useCallback(() => {
        document.body.style.overflow = 'hidden';
        setIsPageMinimized(true);

        setTimeout(() => {
            setIsScrollingDown(false);
            document.body.style.overflow = '';
        }, 650);
    }, []);

    return (
        <header className={"header" + (isPageMinimized || (!isHomePage && isBannerMinimized) ? ' min' : '')}>
            <HeaderBanner isBannerMinimized={isBannerMinimized} isPopupActive={isPopupActive}/>
            <HeaderMenu showFeedbackPopup={showFeedbackPopup} showSharePopup={showSharePopup} user={user}/>
            {!isBioPage && !isLawFirmsPage && !isVendorsPage && !isSignInPage && !isSignUpPage && !isForgotPass && !isResetPass && !isResendPage && !isActivatePage && !isProfilePage &&
                <HeaderDescription/>}
            {!isBioPage && !isLawFirmsPage && !isSignInPage && !isSignUpPage && !isProfilePage && !isForgotPass && !isResetPass && !isAttorneyRulesPage && !isResendPage && !isActivatePage && !isResendPage && !isActivatePage && !isPrivacyPolicyPage && !isCookiePolicyPage && !isDMCAPolicyPage && !isTermsOfUsePage &&  !isVendorsPage &&
                <HeaderSearch minimizeHeader={minimizeHeader} minimizeBanner={minimizeBanner} onSearch={onSearch}/>}
            {(isIndexPage && searchSended && !isBioPage && !isLawFirmsPage && !isVendorsPage && !isSignInPage && !isSignUpPage && !isForgotPass && !isResetPass && !isProfilePage &&
                !isResendPage && !isActivatePage && !isPrivacyPolicyPage && !isCookiePolicyPage && !isDMCAPolicyPage && !isTermsOfUsePage && !isAttorneyRulesPage) ?
                <HeaderIndexVendorsTitle scrollOffset={scrollOffset}/> : ''}
        </header>
    );
};

export default Header;