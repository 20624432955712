// src/context/UserContext.js

import React, { createContext, useContext, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import api from '../api'; // Используем существующий экземпляр Axios

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [authTokens, setAuthTokens] = useState(() => {
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');
        return accessToken && refreshToken ? { access: accessToken, refresh: refreshToken } : null;
    });
    const [loading, setLoading] = useState(true);

    // Используем useRef для хранения актуальных токенов
    const tokensRef = useRef(authTokens);
    useEffect(() => {
        tokensRef.current = authTokens;
    }, [authTokens]);

    // Функция выхода из системы
    const logout = useCallback(() => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        console.log("User has been logged out.");
    }, []);

    // Функция для обновления токена с очередью запросов
    const refreshTokenFunc = useCallback(async () => {
        const currentRefreshToken = tokensRef.current?.refresh;
        if (!currentRefreshToken) {
            console.error("No refresh token available.");
            logout();
            return null;
        }
        try {
            console.log("Attempting to refresh tokens...");
            const response = await api.post('/token/refresh/', {
                refresh: currentRefreshToken
            });
            const newAccessToken = response.data.access;
            const newRefreshToken = response.data.refresh || currentRefreshToken; // Обновляем refresh, если предоставлен
            const newTokens = {
                access: newAccessToken,
                refresh: newRefreshToken,
            };
            // Обновляем только если новый access токен отличается
            if (newTokens.access !== tokensRef.current?.access) {
                setAuthTokens(newTokens);
                localStorage.setItem('access_token', newTokens.access);
                localStorage.setItem('refresh_token', newTokens.refresh);
                console.log("Tokens refreshed successfully.");
            }
            return newAccessToken;
        } catch (error) {
            console.error("Failed to refresh token:", error.response?.status, error.response?.data);
            logout(); // Вызов функции выхода при неудаче обновления токенов
            return null;
        }
    }, [logout]);

    // Очередь для обработки одновременных запросов при обновлении токенов
    const isRefreshing = useRef(false);
    const failedQueue = useRef([]);

    const processQueue = useCallback((error, token = null) => {
        failedQueue.current.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });
        failedQueue.current = [];
    }, []);

    // Интерцептор для автоматического обновления токенов при 401 ошибках
    useEffect(() => {
        const requestInterceptor = api.interceptors.request.use(
            config => {
                if (tokensRef.current?.access) {
                    config.headers['Authorization'] = `Bearer ${tokensRef.current.access}`;
                }
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        const responseInterceptor = api.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;

                if (error.response?.status === 401 && !originalRequest._retry) {
                    if (isRefreshing.current) {
                        // Если уже идет обновление токенов, добавляем запрос в очередь
                        return new Promise((resolve, reject) => {
                            failedQueue.current.push({ resolve, reject });
                        }).then(token => {
                            originalRequest.headers['Authorization'] = `Bearer ${token}`;
                            return api(originalRequest);
                        }).catch(err => {
                            return Promise.reject(err);
                        });
                    }

                    originalRequest._retry = true;
                    isRefreshing.current = true;

                    return new Promise(async (resolve, reject) => {
                        try {
                            const newAccessToken = await refreshTokenFunc();
                            if (!newAccessToken) {
                                throw new Error("Unable to refresh token.");
                            }
                            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                            processQueue(null, newAccessToken);
                            resolve(api(originalRequest));
                        } catch (err) {
                            processQueue(err, null);
                            reject(err);
                        } finally {
                            isRefreshing.current = false;
                        }
                    });
                }

                return Promise.reject(error);
            }
        );

        return () => {
            api.interceptors.request.eject(requestInterceptor);
            api.interceptors.response.eject(responseInterceptor);
        };
    }, [refreshTokenFunc, processQueue]);

    // Получение данных пользователя
    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                if (!authTokens) {
                    setLoading(false);
                    return;
                }
                const response = await api.get('/user/');
                setUser(response.data);
                console.log("User data fetched successfully.");
            } catch (error) {
                if (error.response?.status === 401) {
                    console.warn("Access token expired, attempting to refresh.");
                    const newAccessToken = await refreshTokenFunc();
                    if (newAccessToken) {
                        try {
                            const response = await api.get('/user/');
                            setUser(response.data);
                            console.log("User data fetched successfully after token refresh.");
                        } catch (error2) {
                            console.error("Error fetching user data after refresh:", error2);
                            setUser(null);
                        }
                    } else {
                        console.warn("Refresh token invalid or expired. Logging out.");
                        setUser(null);
                    }
                } else {
                    console.error('Error fetching user data:', error);
                    setUser(null);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [authTokens, refreshTokenFunc]);

    // Состояния и функции для управления всплывающими окнами
    const [isVendorPopupOpen, setIsVendorPopupOpen] = useState(false);
    const [isLawFirmPopupOpen, setIsLawFirmPopupOpen] = useState(false);
    const [shouldOpenCreateVendorPopup, setShouldOpenCreateVendorPopup] = useState(false);
    const [shouldOpenCreateLawFirmPopup, setShouldOpenCreateLawFirmPopup] = useState(false);

    const openVendorPopup = useCallback(() => setIsVendorPopupOpen(true), []);
    const closeVendorPopup = useCallback(() => setIsVendorPopupOpen(false), []);
    const openLawFirmPopup = useCallback(() => setIsLawFirmPopupOpen(true), []);
    const closeLawFirmPopup = useCallback(() => setIsLawFirmPopupOpen(false), []);

    const setCreateVendorFlag = useCallback(() => {
        setShouldOpenCreateVendorPopup(true);
    }, []);
    const setCreateLawFirmFlag = useCallback(() => {
        setShouldOpenCreateLawFirmPopup(true);
    }, []);

    const resetCreateVendorFlag = useCallback(() => {
        setShouldOpenCreateVendorPopup(false);
    }, []);
    const resetCreateLawFirmFlag = useCallback(() => {
        setShouldOpenCreateLawFirmPopup(false);
    }, []);

    // Значения контекста, мемоизированные для оптимизации
    const value = useMemo(() => ({
        user,
        setUser,
        authTokens,
        setAuthTokens,
        refreshToken: refreshTokenFunc,
        logout,
        isVendorPopupOpen,
        isLawFirmPopupOpen,
        openVendorPopup,
        openLawFirmPopup,
        closeVendorPopup,
        closeLawFirmPopup,
        shouldOpenCreateVendorPopup,
        shouldOpenCreateLawFirmPopup,
        setCreateVendorFlag,
        setCreateLawFirmFlag,
        resetCreateVendorFlag,
        resetCreateLawFirmFlag,
        loading
    }), [
        user,
        authTokens,
        refreshTokenFunc,
        logout,
        isVendorPopupOpen,
        isLawFirmPopupOpen,
        shouldOpenCreateVendorPopup,
        shouldOpenCreateLawFirmPopup,
        loading
    ]);

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

// Хук для использования контекста пользователя
export const useUser = () => {
    return useContext(UserContext);
};
