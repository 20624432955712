import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals
import InputWithButtonCopy from "../Form/Input/InputWithButtonCopy";
import "./modal.css";

const BASE_URL = process.env.REACT_APP_BASE_URL || "https://befaster.online";

export default function SharePopup({ closePopup, shareAddress, copyEmbed, companyName, telephone, address }) {
    const [showCopied, setShowCopied] = useState(false);

    const handleClick = useCallback(() => {
        closePopup();
    }, [closePopup]);

    const handleClickCopy = useCallback((e) => {
        e.preventDefault();
        const embedCode = `<div style="text-align: center;">
            <a href="${shareAddress}" target="_blank" style="text-decoration: none;">
                <img src="${BASE_URL}/assets/logo--img-Bjfxg2Ti.svg" alt="Your Company Logo" style="width: 50px; height: 50px;">
            </a>
        </div>`;
        navigator.clipboard.writeText(embedCode);
        setShowCopied(true);
        setTimeout(hideCopied, 2000);
    }, [shareAddress]);

    const hideCopied = useCallback(() => setShowCopied(false), []);

    const handleFacebookShare = useCallback(() => {
        const fbShareUrl = `https://www.facebook.com/dialog/share?app_id=${process.env.REACT_APP_FB_APP_ID}&display=popup&href=${encodeURIComponent(shareAddress)}`;
        window.open(fbShareUrl, '_blank', 'width=600,height=400');
    }, [shareAddress]);

    const handleWhatsAppShare = useCallback(() => {
        const message = `*${companyName}*\n*Phone:* ${telephone}\n*Address:* ${address}\n${shareAddress}`;
        const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
        window.open(whatsappShareUrl, '_blank');
    }, [shareAddress, companyName, telephone, address]);

    const handleEmailShare = useCallback(() => {
        const subject = `Information about ${companyName}`;
        const body = `${companyName}\nPhone: ${telephone}\nAddress: ${address}\n${shareAddress}`
            .replace(/\n/g, '%0D%0A')
            .replace(/\s/g, '%20');
        const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${body}`;
        window.open(mailtoLink, '_blank');
    }, [shareAddress, companyName, telephone, address]);

    const handleTelegramShare = useCallback(() => {
        const message = `${companyName}\nPhone: ${telephone}\nAddress: ${address}`;
        const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(shareAddress)}&text=${encodeURIComponent(message)}`;
        window.open(telegramShareUrl, '_blank');
    }, [shareAddress, companyName, telephone, address]);

    return ReactDOM.createPortal(
        <>
            <div className="modal modal-share visible">
                <div className="modal__overlay"></div>
                <div className="modal__content">
                    <button onClick={handleClick} className="button no-style close js--close-modal">
                        <img src="/assets/icon--close-white.svg" alt="Close" />
                    </button>
                    <div className="modal__form">
                        <form className="content">
                            <div className='modal-share'>
                                <h2>Share</h2>
                                <div className='modal-share__icons'>
                                    <a href='' onClick={(e) => handleClickCopy(e)} data-copy={copyEmbed}
                                       className='modal-share__icon'>
                                        <img src="/assets/icon--copy.svg" alt="Embed"/>
                                        <span>Embed</span>
                                        {showCopied ? <span className='copied'>Copied</span> : ""}
                                    </a>
                                    <a onClick={handleFacebookShare} className='modal-share__icon'
                                       style={{cursor: 'pointer'}}>
                                        <img src="/assets/icon--fb.svg" alt="Facebook"/>
                                        <span>Facebook</span>
                                    </a>
                                    <a onClick={handleWhatsAppShare} className='modal-share__icon'
                                       style={{cursor: 'pointer'}}>
                                        <img src="/assets/icon--wa.svg" alt="WhatsApp"/>
                                        <span>WhatsApp</span>
                                    </a>
                                    <a onClick={handleTelegramShare} className='modal-share__icon'
                                       style={{cursor: 'pointer'}}>
                                        <img src="/assets/icon--telegram.svg" alt="Telegram"/>
                                        <span>Telegram</span>
                                    </a>
                                    <a onClick={handleEmailShare} className='modal-share__icon'
                                       style={{cursor: 'pointer'}}>
                                        <img src="/assets/icon--email.svg" alt="Email"/>
                                        <span>Email</span>
                                    </a>
                                </div>
                                <InputWithButtonCopy value={shareAddress}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>,
        document.body // Портал в body
    );
}
