// src/components/Form/WorkingHoursDisplay.js
import React from 'react';
import PropTypes from 'prop-types';

const WorkingHoursDisplay = ({ workingHours }) => {
    if (!workingHours || workingHours.length === 0) {
        return <p>Working hours not available.</p>;
    }

    // Функция для форматирования времени в AM/PM
    const formatTimeToAMPM = (time) => {
        if (time.toLowerCase() === 'closed') {
            return 'Closed';
        }
        const [hour, minute] = time.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const adjustedHour = hour % 12 || 12;
        return `${adjustedHour}:${minute < 10 ? '0' + minute : minute} ${period}`;
    };

    // Функция для группировки рабочих часов по последовательным дням с одинаковыми часами
    const groupWorkingHours = (working_hours) => {
        // Используем сокращённые названия дней недели
        const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const hoursMap = daysOfWeek.reduce((map, day) => {
            // Инициализируем все дни как "Closed"
            map[day] = 'Closed';
            return map;
        }, {});

        // Заполняем hoursMap актуальными данными
        working_hours.forEach(({ day_of_week, start_time, end_time }) => {
            if (
                start_time.toLowerCase() === 'closed' ||
                end_time.toLowerCase() === 'closed' ||
                !start_time ||
                !end_time
            ) {
                hoursMap[day_of_week] = 'Closed';
            } else {
                hoursMap[day_of_week] = `${formatTimeToAMPM(start_time)} - ${formatTimeToAMPM(end_time)}`;
            }
        });

        const groupedHours = [];
        let currentRange = null;
        let currentDays = [];

        const finalizeGroup = () => {
            if (currentDays.length > 0) {
                if (currentDays.length === 1) {
                    groupedHours.push(`${currentDays[0]}: ${currentRange}`);
                } else {
                    groupedHours.push(`${currentDays[0]}-${currentDays[currentDays.length - 1]}: ${currentRange}`);
                }
            }
        };

        daysOfWeek.forEach(day => {
            const hours = hoursMap[day];
            if (currentRange === hours) {
                currentDays.push(day);
            } else {
                finalizeGroup();
                currentDays = [day];
                currentRange = hours;
            }
        });

        finalizeGroup();

        return groupedHours;
    };

    const workingHoursFormatted = groupWorkingHours(workingHours);

    return (
        <>
            <span>Working Hours</span>
            <ul className="hours">
                {workingHoursFormatted.map((item, index) => (
                    <li key={index}>
                        <span className="working-hours__day">{item.split(': ')[0]}:</span>
                        <span className="working-hours__time">{item.split(': ')[1]}</span>
                    </li>
                ))}
            </ul>
        </>
    );
};

WorkingHoursDisplay.propTypes = {
    workingHours: PropTypes.arrayOf(PropTypes.shape({
        day_of_week: PropTypes.string.isRequired, // Ожидается сокращённое название дня недели
        start_time: PropTypes.string.isRequired,
        end_time: PropTypes.string.isRequired,
    })).isRequired,
};

export default WorkingHoursDisplay
