// EditSpecializationsPopup.js
import React, { useState, useEffect } from 'react';
import api from '../../api';
import MultiSelectInColumnWithLabelExtension from '../MultiSelects/MultiSelectInColumnWithLabelExtension';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals

const EditSpecializationsPopup = ({ show, onClose, initialData, onSave }) => {
    const [tempSelectedSpecializations, setTempSelectedSpecializations] = useState([]);
    const [allSpecializations, setAllSpecializations] = useState([]);

    useEffect(() => {
        const fetchAllSpecializations = async () => {
            try {
                const response = await api.get('/specializations-full/');
                setAllSpecializations(response.data);
            } catch (error) {
                console.error('Error fetching all specializations:', error);
            }
        };

        fetchAllSpecializations();
    }, []);

    useEffect(() => {
        if (initialData && initialData.specializations && allSpecializations.length > 0) {
            const selectedSpecIds = initialData.specializations.map(spec => spec.specialization_id);
            const selectedSpecs = allSpecializations.filter(spec => selectedSpecIds.includes(spec.specialization_id));
            setTempSelectedSpecializations(selectedSpecs);
        }
    }, [initialData, allSpecializations]);

    const handleSpecializationsChange = (selectedSpecs) => {
        setTempSelectedSpecializations(selectedSpecs);
    };

    const handleSave = (e) => {
        e.preventDefault();
        onSave(tempSelectedSpecializations);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Предотвращаем отправку формы при нажатии Enter
        }
    };

    if (!show) return null;

    return ReactDOM.createPortal(
        <div className="modal visible" id="edit-specializations">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close" />
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSave} onKeyDown={handleKeyDown}>
                        <h2>Edit Types of Services</h2>
                        <MultiSelectInColumnWithLabelExtension
                            label=""
                            options={allSpecializations}
                            onChange={handleSpecializationsChange}
                            placeholder="Select Specializations"
                            initialValue={tempSelectedSpecializations}
                            maxSelectedLabels={3} // Максимальное количество отображаемых чипсов
                        />
                        <button className="button button-black" type="submit">Save</button>
                    </form>
                </div>
            </div>
        </div>,
        document.body // Портал в body
    );
};

export default EditSpecializationsPopup;
