import React, { useCallback } from 'react';

export default function TextareaWithLabel({ name, value, onChange, error, label }) {
    const handlerChange = useCallback((e) => {
        onChange(e);
    }, [onChange]);

    // Определяем, есть ли ошибка
    const hasError = Boolean(error);

    return (
        <label className="input-label">
            <textarea
                name={name}
                onChange={handlerChange}
                className={`input ${value ? 'filled' : ''} ${hasError ? 'input-error' : ''}`}
                value={value}
            />
            <span className="placeholder">{label}</span>
            {error && <span className="error-message">{error}</span>}
        </label>
    );
}
