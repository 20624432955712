import React, {useState, useEffect} from 'react';
import api from '../../api';  // Импорт API для запросов
import Loader from "../Loader";
import Footer from "../Footer/Footer";  // Импорт компонента загрузки

export default function LegalPage({pageType}) {
    const [content, setContent] = useState(null);
    const [error, setError] = useState(null);

    // Получение контента юридической страницы с сервера
    useEffect(() => {
        api.get(`/legal-pages/${pageType}/`)  // Настройте конечную точку API при необходимости
            .then(response => {
                setContent(response.data.content);  // Установка HTML-контента
            })
            .catch(err => {
                console.error('Error fetching legal page:', err);
                setError('Failed to load the page.');  // Сообщение об ошибке на английском
            });
    }, [pageType]);

    // Если произошла ошибка, отображаем сообщение об ошибке
    if (error) {
        return <div className="error">{error}</div>;
    }

    // Если контент ещё не загружен, отображаем загрузчик
    if (!content) {
        return <Loader/>;  // Показать загрузчик во время получения данных
    }

    return (
        <div className='about'>
            {/* Безопасный рендеринг HTML-контента */}
            <div className='profile__block about__header'>
                <div className='container' dangerouslySetInnerHTML={{__html: content}}/>
            </div>
            <Footer/>
        </div>
    );
}


