// src/components/Form/SingleSelect/SingleSelect.js
import React, { useState, useEffect, useCallback } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './style.css';

const SingleSelect = ({ label, value, onChange, values }) => {
    const [selectedValue, setSelectedValue] = useState(value || (values.length > 0 ? values[0].value : ''));

    useEffect(() => {
        setSelectedValue(value || (values.length > 0 ? values[0].value : ''));
    }, [value, values]);

    const handleOnChange = useCallback((e) => {
        setSelectedValue(e.value);
        if (onChange) {
            onChange(e);
        }
    }, [onChange]);

    return (
        <label className="input-label">
            {label && <span className="placeholder">{label}</span>}
            <Dropdown
                value={selectedValue}
                options={values}
                onChange={handleOnChange}
                placeholder=""
            />
        </label>
    );
};

export default SingleSelect;
