import React, {useCallback, useEffect, useRef, useState} from 'react';
import api from '../../api';
import {TextAnimator} from "../../utils/text-animator";
import SliderWithImagesPopup from '../SliderWithImagesPopup';
import AdvCampaignPopup from '../popups/AdvCampaignPopup';
import '../IndexConent/IndexContentEmpty';
import SendOkPopup from "../popups/SendOkPopup";
import {Link, useNavigate} from "react-router-dom";
import {useUser} from "../../context/UserContext";

const VendorContentEmpty = () => {
    const [animator, setAnimator] = useState(null);
    const [hoverDescription, setHoverDescription] = useState(false);
    const [listTitle, setListTitle] = useState('');
    const [listDescription, setListDescription] = useState('');
    const [mediaData, setMediaData] = useState([]);  // Состояние для хранения media_items
    const [isAdvCampaignPopupVisible, setIsAdvCampaignPopupVisible] = useState(false);
    const titleRef = useRef(null);
    const [showSendOkPopup, setShowSendOkPopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        api.get('/advertising-sections/', {params: {template_number: 'vendor'}})
            .then(response => {
                const section = response.data[0];  // Предполагаем, что возвращается массив с одним элементом

                // Используем process.env.REACT_APP_BASE_URL для формирования URL
                const baseUrl = process.env.REACT_APP_BASE_URL.replace(/\/+$/, ''); // Удаляем завершающий слэш, если есть
                const processedMediaItems = section.media_items.map(item => {
                    const url = item.url.replace(/^\/+/, ''); // Удаляем начальные слэши из пути
                    return {
                        type: item.type,
                        src: `${baseUrl}/${url}`  // Преобразуем в формат src с новым baseUrl
                    };
                });

                setListTitle(section.title);
                setListDescription(section.description);
                setMediaData(processedMediaItems);  // Устанавливаем обработанные media_items в состояние
            })
            .catch(error => {
                console.error('Error fetching description:', error);
            });
    }, []);

    const handleShowSendOkPopup = useCallback(() => {
        setShowSendOkPopup(true);
        setIsAdvCampaignPopupVisible(false); // Закрываем AdvCampaignPopup при показе OK Popup
    }, []);


    useEffect(() => {
        if (titleRef.current) {
            const animatorInstance = new TextAnimator(titleRef.current);
            setAnimator(animatorInstance);
        }
    }, [listTitle]);

    const animateText = useCallback(() => {
        if (animator) {
            animator.reset();
            animator.animate();
        }
    }, [animator]);

    const handleIntersection = useCallback((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                animateText();
            }
        });
    }, [animateText]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });
        if (titleRef.current) {
            observer.observe(titleRef.current);
        }
        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
        };
    }, [handleIntersection]);

    const handlerHover = useCallback(() => {
        setHoverDescription(true);
        if (!hoverDescription) {
            animateText();
        }
    }, [hoverDescription, animateText]);

    const showAdvCampaignPopup = (e) => {
        e.preventDefault();
        setIsAdvCampaignPopupVisible(true);
    };

    const closeAdvCampaignPopup = useCallback(() => {
        setIsAdvCampaignPopupVisible(false);
    }, []);

    const handlerCloseOk = useCallback(() => {
        setShowSendOkPopup(false);
    }, []);
    const {setCreateVendorFlag} = useUser();

    const handleCreateVendorClick = (e) => {
        e.preventDefault(); // Предотвращаем стандартное поведение ссылки
        setCreateVendorFlag(); // Устанавливаем флаг для отображения попапа
        navigate('/profile'); // Навигация на страницу профиля
    };

    return (
        <div className='about'>
            <div className='content'>
                <div className='about__compaign'>
                    <div className='container'>
                        <div className='text'>
                            <h2>{listTitle}</h2>
                            <p>{listDescription}</p>
                            <div className='button-container'>

                                <a href='' className='button button-black' onClick={showAdvCampaignPopup}>Start
                                    Campaign</a>
                                <button
                                    type="button"
                                    className='button button-border add-info-button'
                                    onClick={handleCreateVendorClick}
                                >
                                    Create Vendor
                                </button>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='about__offices'>
                                <SliderWithImagesPopup mediaData={mediaData}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isAdvCampaignPopupVisible && (
                <AdvCampaignPopup
                    show={isAdvCampaignPopupVisible}
                    closeAdvCampaignPopup={closeAdvCampaignPopup}
                    showSendOkPopup={handleShowSendOkPopup}
                    // Передача функции
                />
            )}
            {showSendOkPopup && <SendOkPopup closePopup={handlerCloseOk}/>}
        </div>
    );
};

export default VendorContentEmpty;
