import React, { useCallback, useContext, useEffect, useState } from 'react';
import RandomLawCompanies from './RandomLawCompanies';
import PopularLawCompanies from './PopularLawCompanies';
import { SearchContext } from '../context/SearchContext';
import { RiveDemo } from '../utils/animateExample'; // Импортируем компонент RiveDemo
import './IndexConent/indexContent.css';
import { TextAnimator } from "../utils/text-animator";

const CombinedPopularLists = ({ onShowPopup, onShowMore }) => {
    const [animator, setAnimator] = useState();
    const [hoverDescription, setHoverDescription] = useState(false);
    const { searchResults, searchSended, loading, loadingMore } = useContext(SearchContext);

    useEffect(() => {
        const element = document.querySelector('.empty-animate');
        if (element) {
            const animatorInstance = new TextAnimator(element);
            setAnimator(animatorInstance);
            animatorInstance.animate(); // Initial animation
        }
    }, []);

    const animateText = useCallback(() => {
        if (animator) {
            animator.reset();
            animator.animate();
        }
    }, [animator]);

    const handlerHover = useCallback(() => {
        setHoverDescription(true);
        if (!hoverDescription) {
            animateText();
        }
    }, [hoverDescription, animateText]);


    if (loading && !loadingMore) { // Проверка состояния загрузки
        return (
            <main className='emptyContent'>
                <div className='content'>
                    <div className='icons'>
                        <RiveDemo/>
                    </div>
                    <span onMouseOver={handlerHover} onMouseLeave={() => setHoverDescription(false)}
                          className='title empty-animate'>{'Loading...'}</span>
                </div>
            </main>
        ); // Используем компонент RiveDemo для отображения во время загрузки
    }

    return (
        <div className="combined-popular-lists">
            {!searchSended || searchResults.length === 0 ? (
                <RandomLawCompanies onShowPopup={onShowPopup}/>
            ) : (
                <PopularLawCompanies onShowPopup={onShowPopup} onShowMore={onShowMore}/>
            )}
        </div>
    );
};

export default CombinedPopularLists;
