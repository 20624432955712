// src/components/Loader.js
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {RiveDemo} from "../utils/animateExample";
import {TextAnimator} from "../utils/text-animator";

const Loader = () => {
    // Хуки должны быть внутри компонента
    const [hoverDescription, setHoverDescription] = useState(false);
    const titleRef = useRef(null);
    const [animator, setAnimator] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        if (titleRef.current) {
            const animatorInstance = new TextAnimator(titleRef.current);
            setAnimator(animatorInstance);
            animatorInstance.animate(); // Запуск анимации сразу после инициализации
        }
    }, [loading]);

    // Определите функцию animateText, если она нужна
    const animateText = useCallback(() => {
        // Реализуйте анимацию текста здесь
    }, []);

    // Обработчик наведения
    const handlerHover = useCallback(() => {
        setHoverDescription(true);
        if (!hoverDescription) {
            animateText();
        }
    }, [hoverDescription, animateText]);

    return (
        <main className="emptyContent">
            <div className='content'>
                <div className='icons'>
                    <RiveDemo/>
                </div>
                <span
                    onMouseOver={handlerHover}
                    onMouseLeave={() => setHoverDescription(false)} // Сбрасываем состояние при уходе курсора
                    className='title loading'
                    ref={titleRef}
                >
                    {'AI Is Loading information...'}
                </span>
            </div>
        </main>
    );
};

export default Loader;
