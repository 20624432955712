import React, {useState} from 'react';
import InputWithLabel from '../Form/Input/InputWithLabel';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals

const EditWorkersPopup = ({show, onClose, onSave, initialData}) => {
    const [formData, setFormData] = useState(() => ({
        workers: initialData?.workers?.map(worker => ({
            ...worker,
            specializations: worker.specializations?.length ? worker.specializations : [{name: ''}],
            positions: worker.positions?.length ? worker.positions : [{title: ''}],
            photo: worker.photo || '',
        })) || []
    }));

    // Состояние для хранения ошибок
    const [errors, setErrors] = useState([]);


    const getWorkerImageUrl = (image) => {
        if (image instanceof File) {
            return URL.createObjectURL(image);
        }
        return image || '/assets/worker-default.png';
    };

    const handleWorkerChange = (index, name, value) => {
        const updatedWorkers = formData.workers.map((worker, i) => {
            if (i === index) {
                if (name === 'specialization') {
                    return {
                        ...worker,
                        specializations: [{name: value}]
                    };
                }
                if (name === 'position') {
                    return {
                        ...worker,
                        positions: [{title: value}]
                    };
                }
                return {...worker, [name]: value};
            }
            return worker;
        });

        setFormData((prevData) => ({
            ...prevData,
            workers: updatedWorkers
        }));

        // Очистка ошибок при изменении значения
        setErrors(prevErrors => {
            const newErrors = [...prevErrors];
            if (newErrors[index]) {
                delete newErrors[index][name];
            }
            return newErrors;
        });
    };

    const handleImageChange = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            validateImage(file, index);
        } else {
            // Если файл удалён
            const updatedWorkers = formData.workers.map((worker, i) => {
                if (i === index) {
                    return {...worker, photo: ''};
                }
                return worker;
            });

            setFormData((prevData) => ({
                ...prevData,
                workers: updatedWorkers
            }));

            setErrors(prevErrors => {
                const newErrors = [...prevErrors];
                if (newErrors[index]) {
                    delete newErrors[index].photo;
                }
                return newErrors;
            });
        }
    };

    // Функция для валидации изображения
    const validateImage = (file, index) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const minWidth = 208;
        const minHeight = 208;

        if (!allowedTypes.includes(file.type)) {
            setErrors(prevErrors => {
                const newErrors = [...prevErrors];
                newErrors[index] = {...newErrors[index], photo: 'Photo must be in JPEG or PNG format.'};
                return newErrors;
            });
            return;
        }

        const img = new Image();
        const objectUrl = URL.createObjectURL(file);
        img.onload = () => {
            const ratio = img.width / img.height;

            if (ratio !== 1) {
                setErrors(prevErrors => {
                    const newErrors = [...prevErrors];
                    newErrors[index] = {...newErrors[index], photo: 'Photo must have a 1:1 aspect ratio.'};
                    return newErrors;
                });
                URL.revokeObjectURL(objectUrl);
                return;
            }

            if (img.width < minWidth || img.height < minHeight) {
                setErrors(prevErrors => {
                    const newErrors = [...prevErrors];
                    newErrors[index] = {
                        ...newErrors[index],
                        photo: `Photo must be at least ${minWidth}px x ${minHeight}px.`
                    };
                    return newErrors;
                });
                URL.revokeObjectURL(objectUrl);
                return;
            }

            // Если все проверки пройдены
            setErrors(prevErrors => {
                const newErrors = [...prevErrors];
                if (newErrors[index]) {
                    delete newErrors[index].photo;
                }
                return newErrors;
            });

            const updatedWorkers = formData.workers.map((worker, i) => {
                if (i === index) {
                    return {...worker, photo: file};
                }
                return worker;
            });

            setFormData(prevData => ({
                ...prevData,
                workers: updatedWorkers
            }));

            URL.revokeObjectURL(objectUrl);
        };
        img.onerror = () => {
            setErrors(prevErrors => {
                const newErrors = [...prevErrors];
                newErrors[index] = {...newErrors[index], photo: 'Invalid image file.'};
                return newErrors;
            });
            URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
    };

    const handleAddWorker = () => {
        setFormData((prevData) => ({
            ...prevData,
            workers: [...prevData.workers, {
                id: null,
                first_name: '',
                last_name: '',
                specializations: [{name: ''}],
                positions: [{title: ''}],
                photo: ''
            }]
        }));
        setErrors(prevErrors => [...prevErrors, {}]); // Добавляем пустой объект ошибок для нового сотрудника
    };

    const handleDeleteWorker = (index) => {
        const updatedWorkers = formData.workers.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            workers: updatedWorkers
        }));
        setErrors(prevErrors => prevErrors.filter((_, i) => i !== index)); // Удаляем ошибки для удалённого сотрудника
    };

    // Функция для валидации обязательных полей сотрудника
    const validateWorker = (worker) => {
        const workerErrors = {};

        if (!worker.first_name.trim()) {
            workerErrors.first_name = 'First name is required.';
        }

        if (!worker.last_name.trim()) {
            workerErrors.last_name = 'Last name is required.';
        }

        if (!worker.specializations || worker.specializations.length === 0 || !worker.specializations[0].name.trim()) {
            workerErrors.specialization = 'Specialization is required.';
        }

        if (!worker.positions || worker.positions.length === 0 || !worker.positions[0].title.trim()) {
            workerErrors.position = 'Position is required.';
        }

        // Добавьте другие проверки обязательных полей здесь, если необходимо

        return workerErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Валидация всех сотрудников
        const newErrors = formData.workers.map(worker => validateWorker(worker));
        setErrors(newErrors);

        // Проверка наличия ошибок
        const hasErrors = newErrors.some(workerErrors => Object.keys(workerErrors).length > 0);
        if (hasErrors) {
            console.error("There are errors in the form.");
            return;
        }

        const formDataToSend = new FormData();
        let hasValidWorker = false;

        for (let index = 0; index < formData.workers.length; index++) {
            const worker = formData.workers[index];

            if (worker.first_name.trim() === '' && worker.last_name.trim() === '') continue;  // Пропуск пустых сотрудников

            if (worker.id) {
                formDataToSend.append(`workers[${index}][id]`, worker.id);
            }
            formDataToSend.append(`workers[${index}][first_name]`, worker.first_name);
            formDataToSend.append(`workers[${index}][last_name]`, worker.last_name);

            // Отправляем specialization как строку, а не массив
            if (worker.specializations && worker.specializations.length > 0) {
                formDataToSend.append(`workers[${index}][specialization]`, worker.specializations[0].name);
            }

            // Отправляем position как строку, а не массив
            if (worker.positions && worker.positions.length > 0) {
                formDataToSend.append(`workers[${index}][position]`, worker.positions[0].title);
            }

            if (worker.photo instanceof File) {
                formDataToSend.append(`workers[${index}][photo]`, worker.photo);
            } else if (!worker.id && !worker.photo) {
                // Если работник новый и нет изображения, загружаем изображение по умолчанию
                const response = await fetch('/assets/worker-default.png');
                const blob = await response.blob();
                const defaultImageFile = new File([blob], 'worker-default.png', {type: 'image/png'});
                formDataToSend.append(`workers[${index}][photo]`, defaultImageFile);
            }

            hasValidWorker = true;  // Найден валидный сотрудник
        }

        if (hasValidWorker) {
            await onSave(formDataToSend);  // Передаем данные только если есть хотя бы один валидный сотрудник
        } else {
            console.error("No valid workers to save.");
        }
    };

    if (!show) return null;

    // Функция для рендеринга инпутов с проверкой ошибок
    const renderInputWithLabel = (index, field, label) => {
        let fieldValue = '';

        // Выводим информацию о работнике и поле в консоль для диагностики

        // Проверяем, массив ли это и есть ли в нем значения
        if (field === 'specialization' && Array.isArray(formData.workers[index].specializations)) {
            fieldValue = formData.workers[index].specializations[0]?.name || '';
        } else if (field === 'position' && Array.isArray(formData.workers[index].positions)) {
            fieldValue = formData.workers[index].positions[0]?.title || '';
        } else {
            fieldValue = formData.workers[index][field] || '';
        }


        return (
            <InputWithLabel
                name={field}
                value={fieldValue}  // Проверяем на null или undefined
                onChange={(e) => handleWorkerChange(index, field, e.target.value)}
                label={label}
                error={errors[index]?.[field]}  // Передача ошибки через пропс
            />
        );
    };

    return ReactDOM.createPortal(
        <div className="modal visible" id="edit-workers">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal" aria-label="Close Popup">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit} noValidate>
                        <h2>Edit Workers</h2>
                        {formData.workers.map((worker, index) => (
                            <React.Fragment key={index}>
                                {renderInputWithLabel(index, 'first_name', 'First Name')}
                                {renderInputWithLabel(index, 'last_name', 'Last Name')}
                                {renderInputWithLabel(index, 'specialization', 'Specialization')}
                                {renderInputWithLabel(index, 'position', 'Position')}

                                {/* Компонент для загрузки изображения */}
                                <div className="profile__logo">
                                    <picture>
                                        <img src={getWorkerImageUrl(worker.photo)}
                                             alt={`${worker.first_name} ${worker.last_name} image`}/>
                                    </picture>
                                    <label className="userpick__edit">
                                        <img src="/assets/icon--edit-bg-BVfPEI-f.svg" alt="Edit"/>
                                        <input type="file" onChange={(e) => handleImageChange(index, e)}
                                               accept="image/jpeg, image/png, image/jpg"/>
                                    </label>
                                </div>
                                {/* Отображение ошибки для изображения, если она есть */}
                                {errors[index] && errors[index].photo && (
                                    <span className="error-message">{errors[index].photo}</span>
                                )}

                                <button
                                    className="button no-style"
                                    type="button"
                                    onClick={() => handleDeleteWorker(index)}
                                >
                                    Delete
                                </button>
                            </React.Fragment>
                        ))}

                        <button
                            className="button button-border"
                            type="button"
                            onClick={handleAddWorker}
                        >
                            Add New Worker
                        </button>
                        <button className="button button-black" type="submit">Save Workers</button>
                    </form>
                </div>
            </div>
        </div>,
        document.body // Портал в body
    );
};

// Валидация пропсов
EditWorkersPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    initialData: PropTypes.shape({
        workers: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            specializations: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string
            })),
            positions: PropTypes.arrayOf(PropTypes.shape({
                title: PropTypes.string
            })),
            photo: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.instanceOf(File)
            ]),
        }))
    }),
};

EditWorkersPopup.defaultProps = {
    initialData: {
        workers: []
    }
};

export default EditWorkersPopup;
