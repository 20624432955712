import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputWithLabel from '../Form/Input/InputWithLabel';
import api from '../../api';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals

const EditAwardsPopup = ({ show, onClose, onSave, initialData }) => {
    // Инициализация состояния формы
    const [formData, setFormData] = useState({
        awards: initialData?.awards || []  // Изначально награды из данных
    });

    // Инициализация состояния ошибок
    const [errors, setErrors] = useState({});

    // Обнуление ошибок при открытии попапа
    useEffect(() => {
        if (show) {
            setErrors({});
        }
    }, [show]);

    // Функция для получения URL изображения награды
    const getAwardImageUrl = (image) => {
        if (image instanceof File) {
            return URL.createObjectURL(image);
        }
        return image || '/assets/logo--img-Bjfxg2Ti2.svg';  // Устанавливаем изображение по умолчанию
    };

    // Обработка изменений в полях награды
    const handleAwardChange = (index, e) => {
        const { name, value } = e.target;
        const updatedAwards = formData.awards.map((award, i) => {
            if (i === index) {
                return { ...award, [name]: value };  // Обновляем данные награды
            }
            return award;
        });

        setFormData((prevData) => ({
            ...prevData,
            awards: updatedAwards
        }));
    };

    // Обработка изменения изображения награды
    const handleImageChange = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const updatedAwards = formData.awards.map((award, i) => {
                if (i === index) {
                    return { ...award, image: file };  // Сохраняем файл изображения
                }
                return award;
            });

            setFormData((prevData) => ({
                ...prevData,
                awards: updatedAwards
            }));
        }
    };

    // Добавление новой награды
    const handleAddAward = () => {
        setFormData((prevData) => ({
            ...prevData,
            awards: [...prevData.awards, { id: null, title: '', subtitle: '', image: '' }]  // Добавляем новую награду
        }));
    };

    // Удаление награды
    const handleDeleteAward = (index) => {
        const updatedAwards = formData.awards.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            awards: updatedAwards
        }));
        // Удаляем ошибки связанные с удаляемой наградой
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[index];
            return updatedErrors;
        });
    };

    // Функция валидации формы
    const validateForm = () => {
        const newErrors = {};

        formData.awards.forEach((award, index) => {
            const awardErrors = {};

            // Проверка названия награды
            if (!award.title || award.title.trim() === '') {
                awardErrors.title = 'Award title is required.';
            }

            // Проверка подзаголовка награды
            if (!award.subtitle || award.subtitle.trim() === '') {
                awardErrors.subtitle = 'Award subtitle is required.';
            }

            // (Опционально) Проверка изображения награды
            // Например, можно проверить тип или размер файла

            if (Object.keys(awardErrors).length > 0) {
                newErrors[index] = awardErrors;
            }
        });

        setErrors(newErrors);

        // Возвращаем true, если ошибок нет
        return Object.keys(newErrors).length === 0;
    };

    // Обработка отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formDataToSend = new FormData();
        let hasValidAward = false;  // Флаг для проверки наличия хотя бы одной заполненной награды

        for (let index = 0; index < formData.awards.length; index++) {
            const award = formData.awards[index];

            // Пропускаем награды с пустым названием и подзаголовком
            if (award.title.trim() === '' && award.subtitle.trim() === '') continue;

            // Добавляем ID, если есть
            if (award.id) {
                formDataToSend.append(`awards[${index}][id]`, award.id);
            }

            formDataToSend.append(`awards[${index}][title]`, award.title);
            formDataToSend.append(`awards[${index}][subtitle]`, award.subtitle);

            // Если изображение изменено (является файлом), добавляем его
            if (award.image instanceof File) {
                formDataToSend.append(`awards[${index}][image]`, award.image);
            } else if (!award.id && !award.image) {
                // Если награда новая и нет изображения, загружаем изображение по умолчанию
                const response = await fetch('/assets/logo--img-Bjfxg2Ti2.svg');
                const blob = await response.blob();
                const defaultImageFile = new File([blob], 'logo--img-Bjfxg2Ti2.svg', { type: 'image/svg+xml' });
                formDataToSend.append(`awards[${index}][image]`, defaultImageFile);
            }

            hasValidAward = true;  // Есть хотя бы одна награда с данными
        }

        if (hasValidAward) {
            await onSave(formDataToSend);  // Передаем данные только если есть хотя бы одна валидная награда
        } else {
            console.error("No valid awards to save");
        }
    };

    // Условный рендеринг попапа
    if (!show) return null;

    return ReactDOM.createPortal(
        <div className="modal visible" id="edit-awards">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal" aria-label="Close Popup">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close" />
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit}>
                        <h2>Edit Awards</h2>
                        {formData.awards.map((award, index) => (
                            <React.Fragment key={index}>
                                <InputWithLabel
                                    name="title"
                                    value={award.title}
                                    onChange={(e) => handleAwardChange(index, e)}
                                    label="Award Or Article"
                                    error={errors[index]?.title}
                                />
                                <InputWithLabel
                                    name="subtitle"
                                    value={award.subtitle}
                                    onChange={(e) => handleAwardChange(index, e)}
                                    label="Additional Information"
                                    error={errors[index]?.subtitle}
                                />

                                {/* Компонент для загрузки изображения */}
                                <div className="profile__logo">
                                    <picture>
                                        <img src={getAwardImageUrl(award.image)} alt={`${award.title} image`} />
                                    </picture>
                                    <label className="userpick__edit">
                                        <img src="/assets/icon--edit-bg-BVfPEI-f.svg" alt="Edit" />
                                        <input type="file" onChange={(e) => handleImageChange(index, e)} />
                                    </label>
                                    {/* Если требуется отображать ошибку для изображения, можно добавить здесь */}
                                    {/* {errors[index]?.image && <span className="error-message">{errors[index].image}</span>} */}
                                </div>

                                <button
                                    className="button no-style"
                                    type="button"
                                    onClick={() => handleDeleteAward(index)}
                                >
                                    Delete
                                </button>
                            </React.Fragment>
                        ))}

                        {/* Кнопка добавления новой награды */}
                        <button
                            className="button button-border"
                            type="button"
                            onClick={handleAddAward}
                        >
                            Add New Award
                        </button>

                        {/* Кнопка отправки формы */}
                        <button className="button button-black" type="submit">Save Awards</button>
                    </form>
                </div>
            </div>
        </div>,
        document.body // Портал в body
    );
};

// Определение типов пропсов для лучшей проверки
EditAwardsPopup.propTypes = {
    show: PropTypes.bool.isRequired, // Флаг отображения попапа
    onClose: PropTypes.func.isRequired, // Функция для закрытия попапа
    onSave: PropTypes.func.isRequired, // Функция для обработки сохранённых данных
    initialData: PropTypes.shape({
        awards: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            title: PropTypes.string,
            subtitle: PropTypes.string,
            image: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
        })),
    }),
};

export default EditAwardsPopup;
