import React, { useState, useEffect, useCallback, useContext } from 'react';
import api from '../api';
import VariousCompanyCards from './VariousCompanyCards';
import { getZipCodeFromCoordinates, getCityFromZipCode } from './geocode';
import fetchCityByIP from './FetchCityByIP';
import { SearchContext } from "../context/SearchContext";

const RandomLawCompanies = ({ onShowPopup, specialization }) => {
    const { changeCityForTitle, changeCompaniesForTitle } = useContext(SearchContext);

    const [lawCompanies, setLawCompanies] = useState([]);
    const [zipCode, setZipCode] = useState(null);
    const [cityName, setCityName] = useState('Los Angeles'); // Город по умолчанию
    const [userLocation, setUserLocation] = useState(null);
    const defaultCityName = 'Los Angeles';
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false); // Добавлено состояние для загрузки при пагинации

    const fetchLawCompanies = useCallback(async (city, specialization, page) => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            const response = await api.post('/legalcompanies-by-city/', { city_name: city, specialization }, {
                params: { page }
            });
            const companies = response.data.results;

            if (page === 1) {
                setLawCompanies(companies);
            } else {
                setLawCompanies(prevCompanies => {
                    const newCompanies = companies.filter(company => !prevCompanies.some(prevCompany => prevCompany.id === company.id));
                    return [...prevCompanies, ...newCompanies];
                });
            }

            if (companies.length <=7) {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching law companies:', error);
        } finally {
            setIsLoading(false);
        }
    }, [isLoading]);

    useEffect(() => changeCompaniesForTitle(lawCompanies), [lawCompanies, changeCompaniesForTitle]);

    useEffect(() => {
        const checkGeolocationPermission = async () => {
            if (navigator.permissions) {
                const permission = await navigator.permissions.query({ name: 'geolocation' });
                if (permission.state === 'granted') {
                    navigator.geolocation.getCurrentPosition(async (position) => {
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;
                        setUserLocation({ latitude, longitude });
                        const zip = await getZipCodeFromCoordinates(latitude, longitude);
                        setZipCode(zip);
                    }, async (error) => {
                        console.error('Error getting geolocation:', error);
                        const city = await fetchCityByIP();
                        if (city) {
                            setCityName(city);
                            changeCityForTitle(city);
                            await fetchLawCompanies(city, specialization, 1);
                        } else {
                            await fetchLawCompanies(defaultCityName, specialization, 1);
                        }
                    });
                } else {
                    const city = await fetchCityByIP();
                    if (city) {
                        setCityName(city);
                        changeCityForTitle(city);
                        await fetchLawCompanies(city, specialization, 1);
                    } else {
                        await fetchLawCompanies(defaultCityName, specialization, 1);
                    }
                }
            } else {
                const city = await fetchCityByIP();
                if (city) {
                    setCityName(city);
                    changeCityForTitle(city);
                    await fetchLawCompanies(city, specialization, 1);
                } else {
                    await fetchLawCompanies(defaultCityName, specialization, 1);
                }
            }
        };

        if (initialLoad) {
            checkGeolocationPermission();
            setInitialLoad(false);
        }
    }, [initialLoad, specialization, fetchLawCompanies, changeCityForTitle]);

    useEffect(() => {
        const fetchCityFromZip = async () => {
            if (zipCode) {
                const city = await getCityFromZipCode(zipCode);
                if (city) {
                    setCityName(city);
                    changeCityForTitle(city);
                    await fetchLawCompanies(city, specialization, 1);
                } else {
                    const cityByIP = await fetchCityByIP();
                    if (cityByIP) {
                        setCityName(cityByIP);
                        changeCityForTitle(cityByIP);
                        await fetchLawCompanies(cityByIP, specialization, 1);
                    } else {
                        await fetchLawCompanies(defaultCityName, specialization, 1);
                    }
                }
            }
        };

        if (zipCode && initialLoad) {
            fetchCityFromZip();
            setInitialLoad(false);
        }
    }, [zipCode, specialization, fetchLawCompanies, initialLoad, changeCityForTitle]);

    useEffect(() => {
        if (initialLoad) {
            fetchLawCompanies(cityName, specialization, 1);
            setInitialLoad(false);
        }
    }, [cityName, specialization, fetchLawCompanies, initialLoad]);

    const loadMoreCompanies = () => {
        setPage(prevPage => {
            const newPage = prevPage + 1;
            setLoadingMore(true);
            fetchLawCompanies(cityName, specialization, newPage).finally(() => {
                setLoadingMore(false);
            });
            return newPage;
        });
    };

    return (
        <div className="accordeon__el">
            <div className="accordeon__content">
                {/*<ul>*/}
                    <VariousCompanyCards lawCompanies={lawCompanies} onShowPopup={onShowPopup} />
                {/*</ul>*/}
                {hasMore && (
                    <div className="show-all">
                        <div className="container">
                            <button onClick={loadMoreCompanies} disabled={loadingMore}>
                                {loadingMore ? 'Loading...' : `Show More Law Companies • ${lawCompanies.length}`}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RandomLawCompanies;
