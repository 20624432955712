import React, {useState, useEffect} from 'react';
import InputSelect from '../Form/Input/InputSelect'; // Импортируем новый компонент
import api from '../../api'; // Импортируем ваш API модуль
import ReactDOM from 'react-dom'; // Import ReactDOM for portals

const EditLanguagesPopup = ({show, closeEditLanguagesPopup, initialData, onSave}) => {
    const [formData, setFormData] = useState({
        languages: initialData?.languages || []
    });
    const [languages, setLanguages] = useState([]);
    const [uniqueLanguages, setUniqueLanguages] = useState([]);

    // Убираем дубликаты по имени языка
    const filterUniqueLanguages = (languages) => {
        const unique = languages.reduce((acc, lang) => {
            if (!acc.find(item => item.name === lang.name)) {
                acc.push(lang);
            }
            return acc;
        }, []);
        return unique;
    };

    useEffect(() => {
        if (initialData) {
            setFormData({
                languages: initialData.languages || []
            });
        }
    }, [initialData]);

    useEffect(() => {
        // Загружаем список языков с сервера
        const fetchLanguages = async () => {
            try {
                const response = await api.get('/languages/');
                setLanguages(response.data);
                const uniqueLangs = filterUniqueLanguages(response.data);
                setUniqueLanguages(uniqueLangs);
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };

        fetchLanguages();
    }, []);

    const handleLanguageChange = (index, e) => {
        const {name, value} = e.target;
        const updatedLanguages = formData.languages.map((language, i) => {
            if (i === index) {
                return {...language, [name]: value};
            }
            return language;
        });

        setFormData((prevData) => ({
            ...prevData,
            languages: updatedLanguages
        }));
    };

    const handleAddLanguageSelect = () => {
        setFormData((prevData) => ({
            ...prevData,
            languages: [...prevData.languages, {language_id: '', name: '', proficiency_level: 'Fluent'}] // Изначально Fluent
        }));
    };

    const handleDeleteLanguage = (index) => {
        const updatedLanguages = formData.languages.filter((_, i) => i !== index);

        setFormData((prevData) => ({
            ...prevData,
            languages: updatedLanguages
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Находим правильный language_id по комбинации name и proficiency_level
        const finalData = formData.languages.map(language => {
            const matchedLanguage = languages.find(
                lang => lang.name === language.name && lang.proficiency_level === language.proficiency_level
            );
            return {
                language_id: matchedLanguage ? matchedLanguage.language_id : null,  // Только language_id
                proficiency_level: language.proficiency_level
            };
        });


        onSave(finalData); // Передача всех данных обратно
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Отключаем отправку формы при нажатии Enter
        }
    };

    if (!show) return null;

    return ReactDOM.createPortal(
        <div className="modal visible" id="edit-languages">
            <div className="modal__overlay" onClick={closeEditLanguagesPopup}></div>
            <div className="modal__content">
                <button onClick={closeEditLanguagesPopup} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                        <h2>Edit Languages</h2>
                        {formData.languages.map((language, index) => (
                            <React.Fragment key={index}>
                                <InputSelect
                                    name="name"
                                    value={language.name}
                                    onChange={(e) => handleLanguageChange(index, e)}
                                    label="Selected language"
                                    options={uniqueLanguages.map((lang) => ({
                                        value: lang.name,
                                        label: lang.name
                                    }))} // Отображаем только уникальные имена
                                    showDelete={true}
                                    onDelete={() => handleDeleteLanguage(index)}
                                    initialProficiency={language.proficiency_level}  // Передаем уровень владения языком

                                />
                            </React.Fragment>
                        ))}

                        <button
                            className="underline no-style"
                            type="button"
                            onClick={handleAddLanguageSelect}
                        >
                            Add New Language
                        </button>

                        <button className="button button-black" type="submit">Save Languages</button>
                    </form>
                </div>
            </div>
        </div>,
        document.body // Портал в body
    );
};

export default EditLanguagesPopup;
