import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputWithLabel from '../Form/Input/InputWithLabel';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals

const EditPreviousWorkplacesPopup = ({ show, onClose, onSave, initialData }) => {
    // Инициализация состояния формы с начальными данными или пустыми полями
    const [formData, setFormData] = useState(() => ({
        previous_working_places: initialData?.previous_working_places?.map(place => ({
            ...place,
            name: place.name || '',
            position: place.position || '',
            start_date: place.start_date || '',
            end_date: place.end_date || ''
        })) || []
    }));

    // Инициализация состояния для хранения ошибок валидации
    const [errors, setErrors] = useState({});

    // Очистка ошибок при открытии попапа
    useEffect(() => {
        if (show) {
            setErrors({});
        }
    }, [show]);

    // Обработка изменений в полях предыдущего места работы
    const handleWorkplaceChange = (index, name, value) => {
        const updatedWorkplaces = formData.previous_working_places.map((place, i) => {
            if (i === index) {
                return { ...place, [name]: value };
            }
            return place;
        });

        setFormData((prevData) => ({
            ...prevData,
            previous_working_places: updatedWorkplaces
        }));
    };

    // Добавление нового места работы
    const handleAddWorkplace = () => {
        setFormData((prevData) => ({
            ...prevData,
            previous_working_places: [...prevData.previous_working_places, {
                name: '',
                position: '',
                start_date: '',
                end_date: ''
            }]
        }));
    };

    // Удаление места работы по индексу
    const handleDeleteWorkplace = (index) => {
        const updatedWorkplaces = formData.previous_working_places.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            previous_working_places: updatedWorkplaces
        }));

        // Удаление ошибок, связанных с удаляемым местом работы
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[index];
            return updatedErrors;
        });
    };

    // Функция валидации формы
    const validateForm = () => {
        const newErrors = {};

        formData.previous_working_places.forEach((place, index) => {
            const placeErrors = {};

            // Проверка названия компании
            if (!place.name || place.name.trim() === '') {
                placeErrors.name = 'Company name is required.';
            }

            // Проверка должности
            if (!place.position || place.position.trim() === '') {
                placeErrors.position = 'Position is required.';
            }

            // Проверка даты начала
            if (!place.start_date) {
                placeErrors.start_date = 'Start date is required.';
            } else if (isNaN(Date.parse(place.start_date))) {
                placeErrors.start_date = 'Start date is invalid.';
            }

            // Проверка даты окончания (если указана)
            if (place.end_date) {
                if (isNaN(Date.parse(place.end_date))) {
                    placeErrors.end_date = 'End date is invalid.';
                } else if (place.start_date && new Date(place.end_date) < new Date(place.start_date)) {
                    placeErrors.end_date = 'End date cannot be before start date.';
                }
            }

            // Если есть ошибки в текущем месте работы, добавляем их в newErrors
            if (Object.keys(placeErrors).length > 0) {
                newErrors[index] = placeErrors;
            }
        });

        setErrors(newErrors);

        // Возвращаем true, если ошибок нет
        return Object.keys(newErrors).length === 0;
    };

    // Обработка отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        // Фильтрация валидных записей о местах работы
        const validWorkplaces = formData.previous_working_places.filter(place => place.name.trim() && place.position.trim());

        if (validWorkplaces.length) {
            await onSave({ previous_working_places: validWorkplaces });
        } else {
            console.error("No valid workplaces to save");
        }
    };

    // Условный рендеринг попапа
    if (!show) return null;

    return ReactDOM.createPortal(
        <div className="modal visible" id="edit-previous-workplaces">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                {/* Кнопка закрытия попапа */}
                <button onClick={onClose} className="button no-style close js--close-modal" aria-label="Close Popup">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close" />
                </button>
                <div className="modal__form">
                    {/* Форма для редактирования предыдущих мест работы */}
                    <form className="content" onSubmit={handleSubmit} noValidate>
                        <h2>Edit Previous Workplaces</h2>
                        {formData.previous_working_places.map((place, index) => (
                            <React.Fragment key={index}>
                                {/* Поле Названия Компании */}
                                <InputWithLabel
                                    name="name"
                                    value={place.name}
                                    onChange={(e) => handleWorkplaceChange(index, 'name', e.target.value)}
                                    label="Company Name"
                                    error={errors[index]?.name}
                                />
                                {/* Поле Должности */}
                                <InputWithLabel
                                    name="position"
                                    value={place.position}
                                    onChange={(e) => handleWorkplaceChange(index, 'position', e.target.value)}
                                    label="Position"
                                    error={errors[index]?.position}
                                />
                                {/* Поле Даты Начала */}
                                <InputWithLabel
                                    name="start_date"
                                    value={place.start_date}
                                    onChange={(e) => handleWorkplaceChange(index, 'start_date', e.target.value)}
                                    label="Start Date"
                                    type="date"
                                    error={errors[index]?.start_date}
                                />
                                {/* Поле Даты Окончания */}
                                <InputWithLabel
                                    name="end_date"
                                    value={place.end_date}
                                    onChange={(e) => handleWorkplaceChange(index, 'end_date', e.target.value)}
                                    label="End Date"
                                    type="date"
                                    error={errors[index]?.end_date}
                                />
                                {/* Кнопка Удаления Места Работы */}
                                <button
                                    className="button no-style"
                                    type="button"
                                    onClick={() => handleDeleteWorkplace(index)}
                                >
                                    Delete
                                </button>
                            </React.Fragment>
                        ))}
                        {/* Кнопка Добавления Нового Места Работы */}
                        <button
                            className="button button-border"
                            type="button"
                            onClick={handleAddWorkplace}
                        >
                            Add New Workplace
                        </button>
                        {/* Кнопка Отправки Формы */}
                        <button className="button button-black" type="submit">Save Workplaces</button>
                    </form>
                </div>
            </div>
        </div>,
        document.body // Портал в body
    );
};

// Определение типов пропсов для лучшей проверки типов
EditPreviousWorkplacesPopup.propTypes = {
    show: PropTypes.bool.isRequired, // Флаг отображения попапа
    onClose: PropTypes.func.isRequired, // Функция для закрытия попапа
    onSave: PropTypes.func.isRequired, // Функция для обработки сохранённых данных
    initialData: PropTypes.shape({
        previous_working_places: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
            position: PropTypes.string,
            start_date: PropTypes.string, // Формат даты: YYYY-MM-DD
            end_date: PropTypes.string,   // Формат даты: YYYY-MM-DD
        })),
    }),
};

export default EditPreviousWorkplacesPopup;
