// src/components/CompanyStatus.js

import React from 'react';
import PropTypes from 'prop-types';

const CompanyStatus = ({ status }) => {
    switch (status) {
        case 'pending':
            return (
                <span className="vendor__status vendor__status-oncheck">ON CHECK</span>
            );
        case 'inactive':
            return (
                <span className="vendor__status vendor__status-oncheck">DRAFT</span>
            );
        default:
            return null; // Не отображать компонент для других статусов
    }
};

CompanyStatus.propTypes = {
    status: PropTypes.string.isRequired,
};

export default CompanyStatus;
