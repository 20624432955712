// MultiSelectInColumnWithLabelExtension.js
import React, { useState, useEffect, useRef } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import styles from './style2.module.css'; // Импортируем как CSS модуль

const MultiSelectInColumnWithLabelExtension = ({
    label,
    options,
    onChange,
    placeholder,
    initialValue,
    maxSelectedLabels = 2, // Максимальное количество отображаемых чипсов
}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const isFirstRender = useRef(true);
    const [extraOptions, setExtraOptions] = useState([]); // Список дополнительных элементов

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            // Фильтруем любые специальные объекты 'more', если они есть в initialValue
            const initialFiltered = (initialValue || []).filter(o => o.specialization_id !== 'more');
            setSelectedOptions(initialFiltered);
            updateExtraOptions(initialFiltered);
        } else if (initialValue && initialValue.length !== selectedOptions.length) {
            const initialFiltered = initialValue.filter(o => o.specialization_id !== 'more');
            setSelectedOptions(initialFiltered);
            updateExtraOptions(initialFiltered);
        }

        // Проверка уникальности specialization_id
        const idSet = new Set(options.map(o => o.specialization_id));
        if (idSet.size !== options.length) {
            console.warn('Есть дублирующиеся specialization_id в options!');
        }
    }, [initialValue, options]);

    const handleChange = (e) => {
        // Фильтруем любые специальные объекты 'more' из выбранных опций
        const filteredOptions = e.value.filter(option => option.specialization_id !== 'more');
        setSelectedOptions(filteredOptions);
        updateExtraOptions(filteredOptions);
        if (onChange) {
            onChange(filteredOptions);
        }
    };

    const updateExtraOptions = (selected) => {
        if (selected.length > maxSelectedLabels) {
            setExtraOptions(selected.slice(maxSelectedLabels));
        } else {
            setExtraOptions([]);
        }
    };

    // Кастомизируем отображение выбранных элементов
    const valueTemplate = (selectedItems) => {
        if (!selectedItems || selectedItems.length === 0) {
            return <span className={styles.placeholder}>{placeholder}</span>;
        }

        const displayItems = selectedItems.slice(0, maxSelectedLabels);
        const remaining = selectedItems.length - maxSelectedLabels;

        return (
            <span className={styles.valueContainer}>
                {displayItems.map((item) => (
                    <span key={item.specialization_id} className={styles.token}>
                        <span className={styles.tokenLabel}>{item.name}</span>
                    </span>
                ))}
                {remaining > 0 && (
                    <span className={`${styles.token} ${styles.tokenPlaceholder}`}>
                        <span className={styles.tokenLabel}>... more {remaining}</span>
                    </span>
                )}
            </span>
        );
    };

    // Определите контекст, чтобы использовать соответствующий класс для .params__list
    // Например, если компонент используется в разных местах, передавайте дополнительный prop или используйте разные компоненты

    return (
        <div className={styles.column}>
            {label && <label className={styles.label}>{label}</label>}
            <MultiSelect
                value={selectedOptions} // Передаём полный список выбранных элементов
                options={options}
                onChange={handleChange}
                optionLabel="name"
                dataKey="specialization_id"
                display="chip" // Используем custom display
                filter
                className={styles.customMultiselect} // Используем локализованный класс
                placeholder={placeholder}
                valueTemplate={valueTemplate} // Используем valueTemplate для отображения
            />
            {extraOptions.length > 0 && (
                <ul className={styles.customParamsList}>
                    {extraOptions.map((item) => (
                        <li key={item.specialization_id}>{item.name}</li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default MultiSelectInColumnWithLabelExtension;
