// src/components/pages/TermsOfUse.js

import React from 'react';
import LegalPage from './LegalPage';

export default function TermsOfUse() {
    return (
        <div>
            <LegalPage pageType="terms_of_use" />
        </div>
    );
}
