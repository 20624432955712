// src/components/User/RegistrationToggle.js

import React from 'react';
import PropTypes from 'prop-types';
// import './RegistrationToggle.css'; // Импортируем CSS для стилизации

const RegistrationToggle = ({ label, type, isChecked, onToggle }) => {
    return (
        <div className='registration-toggle'>
            <label
                onClick={onToggle}
                className={isChecked ? 'toggle checked' : 'toggle'}
            >
                <div className='toggle__container'>
                    <div className='toggle__circle'></div>
                </div>
                <span>{label}</span>
            </label>
        </div>
    );
};

RegistrationToggle.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
};

export default RegistrationToggle;
