// src/components/Form/GoogleButton.js

import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { useNotification } from '../../context/NotificationContext';
import { useUser } from '../../context/UserContext';
import Loader from "../Loader";

const GoogleButton = ({ redirectPath }) => {
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();
    const { setAuthTokens, setCreateVendorFlag } = useUser();
    const navigate = useNavigate();

    const handleGoogleSuccess = async (response) => {
        setLoading(true);

        const code = response.code;

        if (code) {
            try {
                // Отправляем код на бэкенд для обмена на токены
                const backendResponse = await api.post('/auth/google/', {
                    code: code
                });

                if (backendResponse.status === 200) {
                    const tokens = {
                        access: backendResponse.data.access,
                        refresh: backendResponse.data.refresh
                    };
                    setAuthTokens(tokens);
                    localStorage.setItem('access_token', tokens.access);
                    localStorage.setItem('refresh_token', tokens.refresh);

                    // Если требуется установить флаг создания Vendor
                    if (redirectPath === '/sign-up') {
                        setCreateVendorFlag();
                    }

                    showNotification(
                        'Google authentication successful.',
                        'Welcome!',
                        null,
                        'success'
                    );

                    navigate(redirectPath);
                } else {
                    showNotification(
                        'Google authentication failed.',
                        'Please try again.',
                        null,
                        'error'
                    );
                }
            } catch (error) {
                console.error('Google Sign-Up Error:', error);
                showNotification(
                    'Google authentication failed.',
                    'Please try again later.',
                    null,
                    'error'
                );
            } finally {
                setLoading(false);
            }
        } else {
            console.error('Invalid credential response:', response);
            showNotification(
                'Google authentication failed.',
                'Invalid credential response.',
                null,
                'error'
            );
            setLoading(false);
        }
    };

    const handleGoogleFailure = (error) => {
        console.error('Google Sign-Up Error:', error);
        showNotification(
            'Google authentication failed.',
            'Please try again later.',
            null,
            'error'
        );
    };

    const login = useGoogleLogin({
        onSuccess: handleGoogleSuccess,
        onError: handleGoogleFailure,
        flow: 'auth-code', // Используем Authorization Code Flow
        scope: 'openid email profile', // Запрашиваем необходимые разрешения
        redirect_uri: window.location.origin, // Убедитесь, что этот URI указан в Google Cloud Console
    });

    if (loading) {
        return <Loader />;
    }

    return (
        <button
            className="button button-border"
            onClick={() => login()}
            disabled={loading}
            type="button" // Предотвращает отправку формы при нажатии
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <img src="/assets/Google.svg" alt="Google Icon" style={{ marginRight: '8px' }} />
            Continue with Google
        </button>
    );
};

export default GoogleButton;
