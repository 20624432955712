// src/components/popups/ChangePasswordModal.jsx
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import api from '../../api';
import InputWithLabel from '../Form/Input/InputWithLabel'; // Импортируем компонент InputWithLabel
import { useNotification } from '../../context/NotificationContext'; // Импортируем хук уведомлений

const ChangePasswordModal = ({ show, onClose }) => {
    // Состояния для формы
    const [formData, setFormData] = useState({
        current_password: '',
        new_password: ''
    });

    // Состояния для ошибок и успешного сообщения
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    const { showNotification } = useNotification(); // Получаем функцию showNotification из контекста уведомлений

    useEffect(() => {
    }, [show]);

    // Обработчик изменения полей формы
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Функция валидации формы
    const validateForm = () => {
        const newErrors = {};

        if (!formData.current_password) {
            newErrors.current_password = 'Current password is required.';
        }

        if (!formData.new_password) {
            newErrors.new_password = 'New password is required.';
        } else if (formData.new_password.length < 6) {
            newErrors.new_password = 'New password must be at least 6 characters long.';
        }

        return newErrors;
    };

    // Обработчик отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setSuccessMessage('');

        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);

            // Собираем все сообщения об ошибках
            const errorMessages = Object.values(validationErrors).join(' ');

            // Отображаем уведомление об ошибке
            showNotification(
                'Please correct the errors in the form.',
                errorMessages,
                null,
                'error'
            );

            return;
        }

        try {
            // Отправка запроса на смену пароля
            const response = await api.post('/change-password/', {
                current_password: formData.current_password,
                new_password: formData.new_password
            });

            if (response.status === 200) {
                setSuccessMessage('Password has been changed successfully.');

                // Отображаем уведомление об успехе
                showNotification(
                    'Password Changed',
                    'Your password has been updated successfully.',
                    null,
                    'success'
                );

                // Очистка полей формы после успешной смены пароля
                setFormData({
                    current_password: '',
                    new_password: ''
                });
            }
        } catch (error) {
            console.error('Error changing password:', error);

            if (error.response && error.response.data) {
                setErrors(error.response.data);

                // Собираем все сообщения об ошибках из ответа сервера
                const serverErrorMessages = Object.values(error.response.data).join(' ');

                showNotification(
                    'Password Change Failed',
                    serverErrorMessages,
                    null,
                    'error'
                );
            } else {
                setErrors({ general: 'An unexpected error occurred. Please try again.' });

                showNotification(
                    'Error',
                    'An unexpected error occurred. Please try again.',
                    null,
                    'error'
                );
            }
        }
    };

    // Если модалка не должна отображаться, ничего не рендерим
    if (!show) return null;

    // Используем React Portal для рендеринга модалки вне основного DOM-дерева
    return ReactDOM.createPortal(
        <div className="modal visible" onClick={onClose}>
            <div className="modal__overlay"></div>
            <div className="modal__content" onClick={(e) => e.stopPropagation()}>
                <button className="button no-style close js--close-modal" onClick={onClose}>
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close" />
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit}>
                        <h2>Change Password</h2>

                        {/* Сообщение об успехе */}
                        {successMessage && (
                            <div className="success-message">
                                {successMessage}
                            </div>
                        )}

                        {/* Общая ошибка */}
                        {errors.general && (
                            <div className="error-message">
                                {errors.general}
                            </div>
                        )}

                        {/* Поле для текущего пароля */}
                        <InputWithLabel
                            label='Current Password'
                            name='current_password'
                            type='password'
                            value={formData.current_password}
                            onChange={handleChange}
                            hasError={!!errors.current_password}
                            errorMessage={errors.current_password}
                        />

                        {/* Поле для нового пароля */}
                        <InputWithLabel
                            label='New Password'
                            name='new_password'
                            type='password'
                            value={formData.new_password}
                            onChange={handleChange}
                            hasError={!!errors.new_password}
                            errorMessage={errors.new_password}
                        />

                        {/* Кнопка отправки формы */}
                        <button type="submit" className="button button-black">
                            Change Password
                        </button>
                    </form>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default ChangePasswordModal;
