import React, {useState, useEffect} from 'react';
import InputWithLabel from "../Form/Input/InputWithLabel"; // Импортируем компонент для ввода с лейблом
import TextareaWithLabel from "../Form/Input/TextareaWithLabel"; // Импортируем компонент для текстовой области с лейблом
import api from '../../api'; // Импортируем модуль для API запросов
import Cookies from 'js-cookie'; // Импортируем библиотеку для работы с куки
import {useNotification} from '../../context/NotificationContext'; // Импортируем контекст уведомлений (если используется)
import ReactDOM from 'react-dom'; // Import ReactDOM for portals

const AddInformationPopup = ({show, onClose, onUpdateName}) => {
    // Инициализация состояний формы
    const [phone, setPhone] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [firstName, setFirstName] = useState(''); // Состояние для имени
    const [lastName, setLastName] = useState(''); // Состояние для фамилии

    // Состояния для ошибок и загрузки
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    // Получение функции для отображения уведомлений (если используется)
    const {showNotification} = useNotification();

    // Функция для получения конфигурации запроса с CSRF и токеном
    const getRequestConfig = () => {
        const token = localStorage.getItem('authToken'); // Получаем токен из localStorage
        const csrfToken = Cookies.get('csrftoken'); // Получаем CSRF-токен из куки

        return {
            headers: {
                'Authorization': `Bearer ${token}`, // Добавляем заголовок авторизации
                'X-CSRFToken': csrfToken, // Добавляем CSRF-токен
                'Content-Type': 'application/json', // Указываем тип контента
            },
            withCredentials: true, // Включаем отправку куки с запросом
        };
    };

    // useEffect для загрузки данных профиля и пользователя при открытии модального окна
    useEffect(() => {
        if (show) {
            const fetchUserProfile = async () => {
                setIsFetching(true);
                try {
                    const [profileResponse, userResponse] = await Promise.all([
                        api.get('/profile/', getRequestConfig()), // Получаем данные профиля
                        api.get('/user/', getRequestConfig()), // Получаем данные пользователя
                    ]);

                    if (profileResponse.status === 200 && userResponse.status === 200) {
                        const profileData = profileResponse.data;
                        const userData = userResponse.data;
                        setPhone(profileData.phone_number || '');
                        setAdditionalInfo(profileData.additional_information || '');
                        setFirstName(userData.first_name || ''); // Устанавливаем имя
                        setLastName(userData.last_name || ''); // Устанавливаем фамилию
                    } else {
                        setErrors({general: 'Failed to fetch profile and user data. Please try again.'});
                    }
                } catch (error) {
                    console.error('Error fetching profile and user data:', error.response ? error.response.data : error.message);
                    setErrors({general: 'An unexpected error occurred while fetching data. Please try again.'});
                } finally {
                    setIsFetching(false);
                }
            };

            fetchUserProfile();
        } else {
            // Очистка данных при закрытии модального окна
            setPhone('');
            setAdditionalInfo('');
            setFirstName('');
            setLastName('');
            setErrors({});
        }
    }, [show]);

    // Функция для обработки отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Валидация полей формы
        const newErrors = {};
        if (!phone.trim()) newErrors.phone = 'Primary phone number is required';
        if (!firstName.trim()) newErrors.firstName = 'First name is required';
        if (!lastName.trim()) newErrors.lastName = 'Last name is required';

        setErrors(newErrors);

        // Если есть ошибки, не продолжаем
        if (Object.keys(newErrors).length > 0) return;

        setIsLoading(true);

        try {
            // Отправляем запросы на обновление данных пользователя и профиля
            const [profileUpdateResponse, userUpdateResponse] = await Promise.all([
                api.patch('/profile/', {
                    phone_number: phone.trim(),
                    additional_information: additionalInfo.trim(),
                }, getRequestConfig()),

                api.patch('/user/', {
                    first_name: firstName.trim(),
                    last_name: lastName.trim(),
                }, getRequestConfig())
            ]);

            if (profileUpdateResponse.status === 200 && userUpdateResponse.status === 200) {
                showNotification('Information updated successfully!', 'success'); // Показать уведомление об успехе
                onUpdateName(firstName, lastName);  // Вызываем обновление имени и фамилии
                onClose();
            } else {
                setErrors({general: 'Failed to update information. Please try again.'});
                showNotification('Failed to update information. Please try again.', 'error'); // Показать уведомление об ошибке
            }
        } catch (error) {
            console.error('Error updating information:', error.response ? error.response.data : error.message);
            setErrors({general: 'An unexpected error occurred. Please try again.'});
            showNotification('An unexpected error occurred. Please try again.', 'error'); // Показать уведомление об ошибке
        } finally {
            setIsLoading(false);
        }
    };

    return ReactDOM.createPortal(
        <div className={`modal ${show ? 'visible' : ''}`} id="add-information-popup">
            {/* Оверлей для закрытия модального окна при клике */}
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                {/* Кнопка закрытия */}
                <button onClick={onClose} className="button no-style close">
                    <img src="/assets/icon--close.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit}>
                        <h2>Edit Information</h2>

                        {/* Поля для имени и фамилии */}
                        <InputWithLabel
                            label='First Name'
                            name='firstName'
                            type='text'
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            error={errors.firstName}
                        />

                        <InputWithLabel
                            label='Last Name'
                            name='lastName'
                            type='text'
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            error={errors.lastName}
                        />

                        {/* Поле для телефонного номера */}
                        <InputWithLabel
                            label='Primary Phone Number'
                            name='phone'
                            type='tel'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            error={errors.phone}
                        />

                        {/* Поле для дополнительной информации */}
                        <TextareaWithLabel
                            label='Additional Information'
                            name='additionalInfo'
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                            error={errors.additionalInfo}
                        />

                        {/* Общая ошибка */}
                        {errors.general && (
                            <div className="error-message" style={{color: 'red', marginTop: '10px'}}>
                                {errors.general}
                            </div>
                        )}

                        {/* Кнопки отправки и отмены расположены одна над другой */}
                        <button type="submit" className="button button-black" style={{width: '100%'}}
                                disabled={isLoading || isFetching}>
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </button>
                        <button type="button" className="button button-border" onClick={onClose} style={{width: '100%'}}
                                disabled={isLoading || isFetching}>
                            Cancel
                        </button>
                    </form>
                </div>
            </div>
        </div>,
        document.body // Портал в body
    );
};

export default AddInformationPopup;
