import React, { useCallback, useEffect, useRef, useState } from 'react';
import api from '../../api';
import { RiveDemo } from '../../utils/animateExample';
import { TextAnimator } from "../../utils/text-animator";
import { parseISO, format } from 'date-fns';
import { useUser } from "../../context/UserContext";

const BillingInformation = () => {
    const [animator, setAnimator] = useState(null);
    const [billingInfoList, setBillingInfoList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const titleRef = useRef(null);
    const [hoverDescription, setHoverDescription] = useState(false);
    const { user } = useUser();

    useEffect(() => {
        const fetchBillingInfo = async () => {
            try {
                const response = await api.get('/billing-information/');
                setBillingInfoList(response.data);
            } catch (err) {
                console.error('Ошибка при загрузке информации о платежах:', err);
                const errorDetail = err.response ? err.response.data.detail : 'Ошибка при загрузке информации о платежах';
                setError(errorDetail);
            } finally {
                setLoading(false);
            }
        };

        fetchBillingInfo();
    }, []);

    useEffect(() => {
        if (titleRef.current) {
            const animatorInstance = new TextAnimator(titleRef.current);
            setAnimator(animatorInstance);
            animatorInstance.animate(); // Запуск анимации сразу после инициализации
        }
    }, [loading]);

    const animateText = useCallback(() => {
        if (animator) {
            animator.reset();
            animator.animate();
        } else {
        }
    }, [animator]);

    const handleIntersection = useCallback((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                animateText();
            }
        });
    }, [animateText]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });
        if (titleRef.current) {
            observer.observe(titleRef.current);
        }
        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
        };
    }, [handleIntersection]);

    const handlerHover = useCallback(() => {
        setHoverDescription(true);
        if (!hoverDescription) {
            animateText();
        }
    }, [hoverDescription, animateText]);

    const groupInvoicesByDate = (invoices) => {
        const groupedInvoices = {};

        invoices.forEach((invoice) => {
            const dateObj = parseISO(invoice.date); // Преобразуем строку даты в объект Date
            const formattedDate = format(dateObj, 'MMMM d, yyyy'); // Форматируем дату
            const timestamp = dateObj.getTime(); // Получаем временную метку для сортировки

            if (!groupedInvoices[formattedDate]) {
                groupedInvoices[formattedDate] = {
                    date: formattedDate,
                    timestamp: timestamp,
                    invoices: []
                };
            }
            groupedInvoices[formattedDate].invoices.push(invoice);
        });

        // Преобразуем объект в массив и сортируем по timestamp в порядке убывания
        const groupedInvoicesArray = Object.values(groupedInvoices);
        groupedInvoicesArray.sort((a, b) => b.timestamp - a.timestamp);

        return groupedInvoicesArray;
    };

    if (loading) {
        return (
            <main className="emptyContent">
                <div className='content'>
                    <div className='icons'>
                        <RiveDemo />
                    </div>
                    <span
                        onMouseOver={handlerHover}
                        onMouseLeave={() => setHoverDescription(false)}
                        className='title loading'
                        ref={titleRef}
                    >
                        {'Loading Billing Information'}
                    </span>
                </div>
            </main>
        );
    }

    if (error) {
        return (
            <main className="emptyContent">
                <div className='content'>
                    <div className='icons'>
                        <RiveDemo />
                    </div>
                    <span
                        onMouseOver={handlerHover}
                        onMouseLeave={() => setHoverDescription(false)}
                        className='title loading'
                        ref={titleRef}
                    >
                        {'Sorry, an error occurred. Please try again.'}
                    </span>
                </div>
            </main>
        );
    }

    return (
        <div className="profile__block">
            <div className="container">
                <h2>Billing Information for {user.first_name} {user.last_name}</h2>
                {billingInfoList.map((billingInfo, index) => {
                    const groupedInvoices = groupInvoicesByDate(billingInfo.invoices);
                    return (
                        <div key={index}>
                            <div className="billing">
                                <div className="billing__right">
                                    {/* Дополнительный контент, если необходимо */}
                                </div>
                            </div>
                            <div className="profile__content">
                                <div className="billing">
                                    {groupedInvoices.length > 0 ? (
                                        groupedInvoices.map((group) => (
                                            <div key={group.date} className="billing__section">
                                                <h3>{group.date}</h3>
                                                <ul className="billing__list">
                                                    {group.invoices.map((invoice) => (
                                                        <li
                                                            key={invoice.id}
                                                            className={`billing__el ${invoice.payment_status !== 'Paid' ? 'billing__el-trouble' : ''}`}
                                                        >
                                                            <div className="billing__left">
                                                                <div className="line">
                                                                    <span>{invoice.subscription_fee}</span>
                                                                    <span>{invoice.service_name}</span>
                                                                </div>
                                                                <div className="line">
                                                                    <span>Payment Card:</span>
                                                                    <div className="card">
                                                                        <span className="c-gray">{invoice.payment_card}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="billing__right">
                                                                <div className="line">
                                                                    <span>Payment Status:</span>
                                                                    <span className={invoice.payment_status === 'Paid' ? '' : 'c-red'}>
                                                                        {invoice.payment_status}
                                                                    </span>
                                                                </div>
                                                                <div className="line fs-20">
                                                                    <strong>Total Payment:</strong>
                                                                    <strong>${invoice.total_payment}</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))
                                    ) : (
                                        <main className="emptyContent">
                                            <div className='content'>
                                                <div className='icons'>
                                                    <RiveDemo />
                                                </div>
                                                <span
                                                    onMouseOver={handlerHover}
                                                    onMouseLeave={() => setHoverDescription(false)}
                                                    className='title loading'
                                                    ref={titleRef}
                                                >
                                                    {'No billing records found.'}
                                                </span>
                                            </div>
                                        </main>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BillingInformation;
