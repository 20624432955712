import React, {useState, useEffect, useRef, useContext} from 'react';
import api from '../api';
import './loading-spinner.css';
import {SearchContext} from '../context/SearchContext';
import StarsDemo from '../utils/animateWhiteStar';

const SearchForm = ({
                        onSearch,
                        cities,
                        cityName,
                        states,
                        zipCode,
                        specialization,
                        onSpecializationChange,
                        languages,
                        isAllSpecializationActive,
                        freeConsultation,
                        foundationYears,
                        minimizeBanner,
                        minimizeHeader,
                    }) => {
    const [searchText, setSearchText] = useState('');
    const [suggestion, setSuggestion] = useState('');
    const inputRef = useRef(null);
    const {
        setSearchSource,
        setCurrentPage,
        changeSearchSended,
        setSpecialization,
        setProblem,
        setCities,
        setStates,
        setZipCode,
        setLanguage,
        setFreeConsultation,
        setFoundationYears,
        setLoading,
        loading
    } = useContext(SearchContext);

    const prevRequestData = useRef({});

    useEffect(() => {
        setSearchText(specialization !== 'All' ? specialization : '');
    }, [specialization]);
    const handleFocus = () => {
        minimizeBanner();
        minimizeHeader();
    };

    const handleSearch = async (page = 1) => {
        const requestData = {
            problem: searchText,
            cities: cities.length > 0 ? cities.map(city => ({
                city_id: city.city_id || null,
                name: city.name
            })) : [{city_id: null, name: cityName}],
            states: states.map(state => state.state_id),
            zip_code: zipCode,
            specialization,
            language: languages,
            free_consultation: freeConsultation,
            foundation_years: foundationYears,
            page
        };
        setSearchSource('form'); // Устанавливаем источник поиска как 'form'
        minimizeBanner();
        minimizeHeader();

        if (JSON.stringify(prevRequestData.current) === JSON.stringify(requestData)) {
            return;
        }

        prevRequestData.current = requestData;

        setLoading(true);
        setSuggestion('');
        try {
            changeSearchSended();

            const response = await api.post('/get-filtered-specialization/', requestData);

            const aiSuggestion = response.data.suggestion || "AI suggests you contact a company specializing";

            if (onSearch) {
                onSearch({results: response.data.results, count: response.data.count, page, suggestion: aiSuggestion});
                setCurrentPage(page);
                setSpecialization(specialization);
                setProblem(searchText);
                setCities(cities);
                setStates(states);
                setZipCode(zipCode);
                setLanguage(languages);
                setFreeConsultation(freeConsultation);
                setFoundationYears(foundationYears);
            }
        } catch (error) {
            console.error('Error fetching specialization:', error);
            const aiSuggestion = (error.response && error.response.data && error.response.data.suggestion) || "AI suggests you contact a company specializing";
            setSuggestion(aiSuggestion);
            if (onSearch) {
                onSearch({results: [], count: 0, page: 1, suggestion: aiSuggestion});
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                if (searchText.trim().length === 0) {
                    // Если в поле нет данных, ничего не делаем
                    event.preventDefault();
                    return;
                }
                // Если данные есть, запускаем поиск
                event.preventDefault();
                handleSearch(1);
            }
        };

        const inputElement = inputRef.current;
        if (inputElement) {

            inputElement.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [searchText]);

    const handleChange = (e) => {


        setSearchText(e.target.value);
        if (specialization !== 'All') {
            onSpecializationChange('All');
        }
        if (e.target.value.length > 0) {
            e.target.classList.add('fill');
        } else {
            e.target.classList.remove('fill');
        }
    };

    const handleBlur = (e) => {
        if (e.target.value.length > 0) {
            e.target.classList.add('fill');
        } else {
            e.target.classList.remove('fill');
        }
    };

    const handleClear = () => {
        setSearchText('');
        inputRef.current.classList.remove('fill');
    };

    return (
        <form className="search__input">
            <label className="label">
                <input
                    className={`input js--check-fill ${searchText.length > 0 ? 'fill' : ''}`}
                    type="text"
                    value={searchText}
                    ref={inputRef}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}

                />
                <div className="placeholder">
                    <img src="assets/icon--zoom-DblF_61P.svg" alt="Search icon"/>
                    <span>Select a specialization or write your problem</span>
                </div>
                <div className="buttons">
                    <button className="clear js--search-clear" type="button" onClick={handleClear}>
                        <img src="assets/icon--cross-C4Q-00Ld.svg" alt="Clear button"/>
                    </button>
                    <button
                        className='button-black'
                        type="button"
                        onClick={() => handleSearch(1)}
                        disabled={loading}
                    >
                        {loading ? <div className="pressed">
                                <StarsDemo/>
                            </div> :
                            <div className="pulled">
                                <span>Enter →</span>
                            </div>
                        }
                    </button>
                </div>
            </label>
        </form>
    );
};

export default SearchForm;