// AddAddressPopup.js
import React, { useState, useEffect } from 'react';
import api from '../../api';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals

const AddAddressPopup = ({ show, onClose, legalCompanyId, onRegionAdded }) => {
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [address, setAddress] = useState({
        country_id: 1, // Страна по умолчанию с ID 1 (United States)
        state_id: '',
        city_id: '',
    });

    // Загрузка штатов при монтировании компонента
    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await api.get(`/states-filtered/?country_id=1`);
                setStates(response.data);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };
        fetchStates();
    }, []);

    // Обработчик изменений адреса
    const handleAddressChange = async (name, value) => {
        setAddress((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'state_id') {
            await fetchCities(value);
            setAddress((prevData) => ({
                ...prevData,
                city_id: '',
            }));
        }
    };

    // Загрузка городов при выборе штата
    const fetchCities = async (state_id) => {
        try {
            const response = await api.get(`/cities-filtered/?state_id=${state_id}`);
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    // Обработка отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    'Content-Type': 'application/json',
                },
            };

            const dataToSend = {
                country_id: 1,
                state_id: address.state_id,
                city_id: address.city_id,
            };

            const response = await api.post(
                `/legal-companies/${legalCompanyId}/add-service-regions/`,
                dataToSend,
                config
            );

            if (response.status === 200) {
                onRegionAdded(); // Обновляем регионы в родительском компоненте
                onClose(); // Закрываем всплывающее окно
            } else {
                console.error('Error adding service regions:', response.data);
            }
        } catch (error) {
            console.error('Error adding service regions:', error);
        }
    };

    if (!show) return null;

    return ReactDOM.createPortal(
        <div className="modal visible" id="add-address">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close.svg" alt="Close" />
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit}>
                        <h2>Add Service Region</h2>

                        {/* Страна всегда United States и не может быть изменена */}
                        <select className="input" disabled value={1}>
                            <option value={1}>United States</option>
                        </select>

                        {states.length > 0 && (
                            <select
                                className="input"
                                value={address.state_id}
                                onChange={(e) => handleAddressChange('state_id', e.target.value)}
                                required
                            >
                                <option value="" disabled>
                                    Choose state
                                </option>
                                {states.map((state) => (
                                    <option key={state.state_id} value={state.state_id}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        )}

                        {cities.length > 0 && (
                            <select
                                className="input"
                                value={address.city_id}
                                onChange={(e) => handleAddressChange('city_id', e.target.value)}
                                required
                            >
                                <option value="" disabled>
                                    Choose city
                                </option>
                                {cities.map((city) => (
                                    <option key={city.city_id} value={city.city_id}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        )}

                        <button className="button button-black" type="submit">
                            Add Region
                        </button>
                    </form>
                </div>
            </div>
        </div>,
        document.body // Портал в body
    );
};

AddAddressPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    legalCompanyId: PropTypes.number.isRequired,
    onRegionAdded: PropTypes.func.isRequired,
};

export default AddAddressPopup;
