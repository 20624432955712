// src/components/pages/CookiePolicy.js

import React from 'react';
import LegalPage from './LegalPage';

export default function CookiePolicy() {
    return (
        <div>
            <LegalPage pageType="cookie_policy" />
        </div>
    );
}
