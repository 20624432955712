import React, { useState, useEffect } from 'react';
import SingleSelect from "./SingleSelect/SingleSelect";

// Оставляем только реальные дни недели
const daysOfWeek = [
    { label: 'Select a day', value: '' },
    { label: 'Mon', value: 'Mon' },
    { label: 'Tue', value: 'Tue' },
    { label: 'Wed', value: 'Wed' },
    { label: 'Thu', value: 'Thu' },
    { label: 'Fri', value: 'Fri' },
    { label: 'Sat', value: 'Sat' },
    { label: 'Sun', value: 'Sun' },
];

// Определяем порядок дней недели для сортировки
const daysOrder = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

// Функция для генерации опций времени в 12-часовом формате с шагом в 30 минут
const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const time24 = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            const period = hour < 12 ? 'AM' : 'PM';
            const displayHour = hour % 12 === 0 ? 12 : hour % 12;
            const time12 = `${displayHour}:${minute.toString().padStart(2, '0')} ${period}`;

            times.push({ label: time12, value: time24 });
        }
    }

    return times;
};

const timeOptions = generateTimeOptions();

// Функция для сортировки рабочих часов по дням недели
const sortWorkingHours = (workingHours) => {
    return workingHours.slice().sort((a, b) => {
        const dayA = daysOrder.indexOf(a.day_of_week);
        const dayB = daysOrder.indexOf(b.day_of_week);
        return dayA - dayB;
    });
};

const WorkingHoursEditor = ({
    workingHours,
    setWorkingHours,
    onClose
}) => {
    const [localWorkingHours, setLocalWorkingHours] = useState([]);

    useEffect(() => {
        // Инициализируем локальные рабочие часы и сортируем их
        setLocalWorkingHours(sortWorkingHours(workingHours));
    }, [workingHours]);

    const handleAddWorkingHour = () => {
        // Проверяем, не превышает ли количество записей 7
        if (localWorkingHours.length >= 7) {
            alert("You cannot add more than 7 working days.");
            return;
        }
        // Добавляем новый пустой рабочий час
        const newWorkingHour = { day_of_week: '', start_time: '', end_time: '' };
        const updatedWorkingHours = [...localWorkingHours, newWorkingHour];
        setLocalWorkingHours(sortWorkingHours(updatedWorkingHours));
    };

    const handleDeleteWorkingHour = (index) => {
        const updatedWorkingHours = localWorkingHours.filter((_, i) => i !== index);
        setLocalWorkingHours(sortWorkingHours(updatedWorkingHours));
    };

    const handleChangeWorkingHour = (index, field, value) => {
        const updatedWorkingHours = localWorkingHours.map((hour, i) => {
            if (i === index) {
                return { ...hour, [field]: value };
            }
            return hour;
        });
        setLocalWorkingHours(sortWorkingHours(updatedWorkingHours));
    };

    const handleSave = () => {
        // Валидация всех рабочих часов
        for (const hour of localWorkingHours) {
            if (!hour.day_of_week || !hour.start_time || !hour.end_time) {
                alert("Please fill out all fields in all working hours.");
                return;
            }
        }
        // Проверяем уникальность дней недели
        const days = localWorkingHours.map(hour => hour.day_of_week);
        const uniqueDays = new Set(days);
        if (uniqueDays.size !== days.length) {
            alert("Days of the week must be unique.");
            return;
        }
        setWorkingHours(localWorkingHours);
        onClose();
    };

    return (
        <div className="modal visible" id="edit-working-hours">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button className="button no-style close" onClick={onClose}>
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close" />
                </button>
                <div className="modal__form">
                    <div className="content" onSubmit={(e) => e.preventDefault()}>
                        <h2>Edit Working Hours</h2>
                        <ul className='schedule'>
                            {localWorkingHours.map((hour, index) => (
                                <li key={index} className='schedule__el'>
                                    <SingleSelect
                                        placeholder="Select day"
                                        values={daysOfWeek}
                                        value={hour.day_of_week}
                                        onChange={(e) => handleChangeWorkingHour(index, 'day_of_week', e.value)}
                                    />
                                    <SingleSelect
                                        placeholder="Select time"
                                        values={timeOptions}
                                        value={hour.start_time}
                                        onChange={(e) => handleChangeWorkingHour(index, 'start_time', e.value)}
                                    />
                                    <SingleSelect
                                        placeholder="Select time"
                                        values={timeOptions}
                                        value={hour.end_time}
                                        onChange={(e) => handleChangeWorkingHour(index, 'end_time', e.value)}
                                    />

                                    <button
                                        type="button"
                                        className='button no-style'
                                        onClick={() => handleDeleteWorkingHour(index)}
                                    >
                                        <img src='/assets/icon--delete-gray.svg' alt="Delete" />
                                    </button>
                                </li>
                            ))}
                            <li>
                                <button
                                    type="button"
                                    className='button no-style'
                                    onClick={handleAddWorkingHour}
                                >
                                    <img src='/assets/Category.svg' alt="Add" /> Add Working Day
                                </button>
                            </li>
                        </ul>
                        <button className="button button-black" type="button" onClick={handleSave}>
                            Save Working Hours
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkingHoursEditor;
