// src/components/Form/Input/EmailNotificationToggle.js

import React, {useState, useEffect, useCallback} from 'react';
import api from '../../../api'; // Убедитесь, что путь указан правильно

const EmailNotificationToggle = () => {
    const [isSubscribed, setIsSubscribed] = useState(false); // Состояние подписки на email
    const [isLoading, setIsLoading] = useState(false); // Индикатор загрузки
    const [error, setError] = useState(null); // Сообщение об ошибке

    // Получение CSRF токена из куки
    const getCSRFToken = () => {
        const name = 'csrftoken';
        const cookies = document.cookie.split('; ');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].split('=');
            if (cookie[0] === name) {
                return decodeURIComponent(cookie[1]);
            }
        }
        return null;
    };

    // Получение текущего состояния подписки при монтировании компонента
    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            try {
                const response = await api.get('/user/email-subscription/');
                setIsSubscribed(response.data.email_subscription);
            } catch (err) {
                console.error('Ошибка при получении состояния подписки:', err);
                setError('Failed to fetch subscription status.');
            }
        };
        fetchSubscriptionStatus();
    }, []);

    // Функция обновления состояния подписки
    const updateSubscription = useCallback(async (subscribe) => {
        try {
            await api.put(
                '/user/email-subscription/',
                {email_subscription: subscribe},
                {
                    headers: {
                        'X-CSRFToken': getCSRFToken(),
                    },
                    withCredentials: true, // Отправка куки для CSRF
                }
            );
            setIsSubscribed(subscribe);
            showNotification(
                subscribe ? 'Subscribed Successfully!' : 'Unsubscribed Successfully!',
                subscribe
                    ? 'You will now receive email notifications.'
                    : 'You will no longer receive email notifications.',
                subscribe ? 'success' : 'info'
            );
        } catch (err) {
            console.error('Ошибка при обновлении подписки:', err);
            setError('Failed to update subscription.');
        }
    }, []);

    // Обработчик переключения
    const handleToggle = async () => {
        setIsLoading(true);
        setError(null);
        await updateSubscription(!isSubscribed);
        setIsLoading(false);
    };

    // Функция отображения уведомлений (можно заменить на библиотеку, например, react-toastify)
    const showNotification = (title, message, type) => {
        // Реализуйте вашу логику отображения уведомлений здесь
    };

    return (
        <div className="toggle">
            <div className="billing-toggle-container">
                <div className="billing-toggle">
                    <div
                        className={`toggle ${isSubscribed ? 'checked' : ''}`}
                        onClick={!isLoading ? handleToggle : null}
                        role="switch"
                        aria-checked={isSubscribed}
                        tabIndex={0}
                        onKeyPress={(e) => {
                            if (!isLoading && (e.key === 'Enter' || e.key === ' ')) {
                                handleToggle();
                            }
                        }}
                        style={{
                            cursor: isLoading ? 'not-allowed' : 'pointer',
                            opacity: isLoading ? 0.5 : 1,
                        }}
                    >
                        <div className="toggle__container">
                            <div className="toggle__circle"></div>
                        </div>
                    </div>
                </div>
                {error && <span className="error-message">{error}</span>}
            </div>
        </div>
    );
};

// Утилита для получения CSRF токена из куки
function getCSRFToken() {
    const name = 'csrftoken';
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        if (cookie[0] === name) {
            return decodeURIComponent(cookie[1]);
        }
    }
    return null;
}

export default EmailNotificationToggle; // Исправлено на правильный экспорт
