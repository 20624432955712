import React, {useState, useEffect, useCallback} from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals

import MultiSelectInColumnWithLabel from '../../components/MultiSelects/MultiSelectInColumnWithLabel';
import InputSelect from '../Form/Input/InputSelect';
import InputWithLabel from '../Form/Input/InputWithLabel';
import TextareaWithLabel from "../Form/Input/TextareaWithLabel";
import api from '../../api';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types'; // Для валидации пропсов

export default function AddAttorneyPopup({show, onClose, legalCompanyId, onAttorneyAdded, initialBarNumber}) {
    const currentYear = new Date().getFullYear();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        licenseYear: '',
        specializations: [],
        registrationNumber: '', // Это поле будет заполнено initialBarNumber
        phoneNumber: '',
        description: '',
        languages: [],
        photo: null,
        photoRatio: null, // Для хранения соотношения сторон изображения
    });

    const [previewUrl, setPreviewUrl] = useState(null); // Для предварительного просмотра изображения
    const [errors, setErrors] = useState({});
    const [specializations, setSpecializations] = useState([]);
    const [uniqueLanguages, setUniqueLanguages] = useState([]);

    // Убираем дубликаты по имени языка
    const filterUniqueLanguages = useCallback((languages) => {
        const uniqueLanguages = [];
        const seenNames = new Set();

        languages.forEach(lang => {
            if (!seenNames.has(lang.name)) {
                seenNames.add(lang.name);
                uniqueLanguages.push(lang);
            }
        });

        return uniqueLanguages;
    }, []);

    // Запрос специализаций и языков при монтировании компонента или при изменении initialBarNumber
    useEffect(() => {
        const fetchSpecializationsAndLanguages = async () => {
            try {
                // Загружаем специализации
                const specializationsResponse = await api.get('/specializations-full/');
                setSpecializations(specializationsResponse.data.map(spec => ({
                    name: spec.name,
                    code: spec.specialization_id
                })));

                // Загружаем языки
                const languagesResponse = await api.get('/languages/');
                const uniqueLangs = filterUniqueLanguages(languagesResponse.data);
                setUniqueLanguages(uniqueLangs);  // Сохраняем уникальные языки
            } catch (error) {
                console.error('Error fetching specializations or languages:', error);
            }
        };

        fetchSpecializationsAndLanguages();
    }, [filterUniqueLanguages]);

    // Инициализация поля registrationNumber с initialBarNumber
    useEffect(() => {
        if (initialBarNumber) {
            setFormData(prevData => ({
                ...prevData,
                registrationNumber: initialBarNumber
            }));
        }
    }, [initialBarNumber]);

    // Очистка предварительного просмотра при размонтировании компонента
    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);

    const handleChange = useCallback((e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }, []);

    const handleFileChange = useCallback((e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    photo: 'Photo must be a JPEG or PNG image',
                }));
                setFormData(prevData => ({
                    ...prevData,
                    photo: null,
                    photoRatio: null,
                }));
                setPreviewUrl(null); // Очищаем предварительный просмотр
                return;
            }

            const img = new Image();
            const objectUrl = URL.createObjectURL(file);
            img.onload = () => {
                const ratio = img.width / img.height;
                const minWidth = 208;
                const minHeight = 208;

                if (ratio !== 1) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        photo: 'Photo must have a 1:1 aspect ratio',
                    }));
                    setFormData(prevData => ({
                        ...prevData,
                        photo: null,
                        photoRatio: null,
                    }));
                    setPreviewUrl(null);
                } else if (img.width < minWidth || img.height < minHeight) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        photo: `Photo must be at least ${minWidth}px x ${minHeight}px`,
                    }));
                    setFormData(prevData => ({
                        ...prevData,
                        photo: null,
                        photoRatio: null,
                    }));
                    setPreviewUrl(null);
                } else {
                    // Если все проверки пройдены, сохраняем фото и очищаем ошибки
                    setErrors(prevErrors => {
                        const {photo, ...rest} = prevErrors;
                        return rest;
                    });
                    setFormData(prevData => ({
                        ...prevData,
                        photo: file, // Корректно обновляем 'photo'
                        photoRatio: ratio,
                    }));
                    setPreviewUrl(objectUrl); // Устанавливаем URL для предварительного просмотра
                }
            };
            img.onerror = () => {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    photo: 'Invalid image file',
                }));
                setFormData(prevData => ({
                    ...prevData,
                    photo: null,
                    photoRatio: null,
                }));
                setPreviewUrl(null);
                URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
        } else {
            setFormData(prevData => ({
                ...prevData,
                photo: null,
                photoRatio: null,
            }));
            setErrors(prevErrors => {
                const {photo, ...rest} = prevErrors;
                return rest;
            });
            setPreviewUrl(null);
        }
    }, []);

    const handleSpecializationChange = useCallback((selectedSpecializations) => {

        // Фильтруем специализации, чтобы убрать null значения
        const validSpecializations = selectedSpecializations
            .filter(spec => spec.code !== null)
            .map(spec => spec.code);

        setFormData((prevData) => ({
            ...prevData,
            specializations: validSpecializations,
        }));
    }, []);

    const handleLanguageChange = useCallback((index, e) => {
        const {value} = e.target;

        // Находим объект языка по названию
        const selectedLanguage = uniqueLanguages.find(lang => lang.name === value);

        setFormData((prevData) => {
            const updatedLanguages = prevData.languages.map((language, i) => {
                if (i === index) {
                    return {
                        ...language,
                        name: value,
                        language_id: selectedLanguage ? selectedLanguage.language_id : ''
                    };
                }
                return language;
            });
            return {
                ...prevData,
                languages: updatedLanguages,
            };
        });
    }, [uniqueLanguages]);

    const handleAddLanguageSelect = useCallback(() => {
        setFormData((prevData) => ({
            ...prevData,
            languages: [...prevData.languages, {language_id: '', name: '', proficiency_level: 'Fluent'}]
        }));
    }, []);

    const handleDeleteLanguage = useCallback((index) => {
        const updatedLanguages = formData.languages.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            languages: updatedLanguages,
        }));
    }, [formData.languages]);

    // Валидация данных формы
    const validateForm = useCallback(() => {
        const newErrors = {};

        // Валидация имени
        if (!formData.firstName.trim()) {
            newErrors.firstName = 'First name is required';
        }

        // Валидация фамилии
        if (!formData.lastName.trim()) {
            newErrors.lastName = 'Last name is required';
        }

        // Валидация email
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                newErrors.email = 'Invalid email format';
            }
        }

        // Валидация номера телефона
        if (!formData.phoneNumber.trim()) {
            newErrors.phoneNumber = 'Phone number is required';
        } else {
            const phoneRegex = /^\+?[0-9\s\-()]{7,20}$/;
            if (!phoneRegex.test(formData.phoneNumber)) {
                newErrors.phoneNumber = 'Invalid phone number format';
            }
        }

        // Валидация года лицензии
        if (!formData.licenseYear) {
            newErrors.licenseYear = 'License year is required';
        } else {
            const year = Number(formData.licenseYear);
            if (isNaN(year) || year < 1900 || year > currentYear) {
                newErrors.licenseYear = `License year must be between 1900 and ${currentYear}`;
            }
        }

        // Валидация регистрационного номера
        if (!formData.registrationNumber.trim()) {
            newErrors.registrationNumber = 'Registration number is required';
        }

        // Валидация описания
        if (formData.description.trim().length < 300) {
            newErrors.description = 'Description must be at least 300 characters';
        }

        // Валидация специализаций
        if (!formData.specializations || formData.specializations.length === 0) {
            newErrors.specializations = 'At least one specialization must be selected';
        }

        // Валидация языков
        // if (!formData.languages || formData.languages.length === 0) {
        //     newErrors.languages = 'At least one language must be selected';
        // } else {
        //     const invalidLanguages = formData.languages.filter(lang => !lang.language_id);
        //     if (invalidLanguages.length > 0) {
        //         newErrors.languages = 'Each language must have a valid ID.';
        //     }
        // }

        // Валидация изображения
        if (formData.photo) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!allowedTypes.includes(formData.photo.type)) {
                newErrors.photo = 'Photo must be a JPEG or PNG image';
            }
            if (formData.photoRatio !== 1) {
                newErrors.photo = 'Photo must have a 1:1 aspect ratio';
            }
            // Проверка минимальных размеров уже выполнена в handleFileChange
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
        }

        return Object.keys(newErrors).length === 0;
    }, [formData, currentYear]);

    const getRequestConfig = () => {
        const token = localStorage.getItem('authToken');
        const csrfToken = Cookies.get('csrftoken');
        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-CSRFToken': csrfToken,  // Оставляем CSRF токен
            },
            withCredentials: true,
        };
    };

    const handleAddAttorney = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('first_name', formData.firstName);
        formDataToSend.append('last_name', formData.lastName);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('license_year', formData.licenseYear);
        formDataToSend.append('registration_number', formData.registrationNumber);
        formDataToSend.append('phone_number', formData.phoneNumber);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('specializations', JSON.stringify(formData.specializations));
        formDataToSend.append('languages', JSON.stringify(formData.languages.map(lang => lang.language_id)));

        if (formData.photo) {
            formDataToSend.append('photo', formData.photo);
        }


        try {
            const config = getRequestConfig();
            let endpoint = '';
            if (legalCompanyId) {
                // Если legalCompanyId присутствует, отправляем данные на сервер для добавления к юридической компании
                endpoint = `/legal-companies/${legalCompanyId}/attorneys/`;
            } else {
                // Если legalCompanyId отсутствует, отправляем данные на другой API-эндпоинт для самостоятельного создания адвоката
                endpoint = '/attorneys/create/';
            }

            const response = await api.post(endpoint, formDataToSend, config);

            if (response.status === 201 || response.status === 200) { // 200 OK также может быть возвращен
                // Предполагаем, что API возвращает данные нового адвоката
                onAttorneyAdded(response.data); // Передаем данные нового адвоката родителю
                onClose();
            } else {
                console.error('Error adding attorney:', response);
                setErrors({general: 'Error adding attorney. Please try again.'});
            }
        } catch (error) {
            console.error('Error adding attorney:', error.response ? error.response.data : error.message);
            setErrors({general: 'Error adding attorney. Please try again.'});
        }
    };

    if (!show) return null;

    return ReactDOM.createPortal(
        <div className="modal visible">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleAddAttorney}>
                        <h2>Add New Attorney</h2>

                        {/* Общие ошибки */}
                        {errors.general && (
                            <div className="error-message">
                                {errors.general}
                            </div>
                        )}

                        {/* Поле Фото */}
                        <div className="profile__logo" style={{position: 'relative'}}>
                            <picture>
                                <img
                                    src={previewUrl ? previewUrl : '/assets/logo--img-Bjfxg2Ti2.svg'}
                                    alt="Attorney Photo"
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'cover'
                                    }} // Обеспечиваем соотношение 1:1
                                />
                            </picture>
                            <label className="userpick__edit">
                                <img src="/assets/icon--edit-bg-BVfPEI-f.svg" alt="Edit"/>
                                <input type="file" name="photo" onChange={handleFileChange}
                                       accept="image/jpeg, image/png, image/jpg"/>
                            </label>
                        </div>
                        {errors.photo && (
                            <span className="error-message">
                                    {errors.photo}
                                </span>
                        )}

                        <InputWithLabel
                            name="firstName"
                            label="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            error={errors.firstName}
                        />
                        <InputWithLabel
                            name="lastName"
                            label="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            error={errors.lastName}
                        />
                        <InputWithLabel
                            name="phoneNumber"
                            label="Phone Number"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            error={errors.phoneNumber}
                        />
                        <InputWithLabel
                            name="email"
                            label="Email"
                            value={formData.email}
                            onChange={handleChange}
                            error={errors.email}
                        />
                        <InputWithLabel
                            name="licenseYear"
                            label="License Year"
                            value={formData.licenseYear}
                            onChange={handleChange}
                            type="number"
                            min="1900"
                            max={currentYear}
                            error={errors.licenseYear}
                        />
                        <InputWithLabel
                            name="registrationNumber"
                            label="Registration Number"
                            value={formData.registrationNumber}
                            onChange={handleChange}
                            error={errors.registrationNumber}
                        />
                        <TextareaWithLabel
                            label='Description'
                            name='description'
                            value={formData.description}
                            onChange={handleChange}
                            error={errors.description}
                        />
                        <div className="form-group">
                            <label className="form-label">Specializations</label>
                            <div className="form-input">
                                <MultiSelectInColumnWithLabel
                                    options={specializations}
                                    onChange={handleSpecializationChange}
                                    placeholder="Select specializations"
                                />
                            </div>
                            {errors.specializations && (
                                <span className="error-message">{errors.specializations}</span>
                            )}
                        </div>

                        {/*/!* Поле Языки *!/*/}
                        {/*<div className="form-group">*/}
                        {/*    <label className="form-label">Languages</label>*/}
                        {/*    {formData.languages.map((language, index) => (*/}
                        {/*        <div key={index} className="language-select">*/}
                        {/*            <InputSelect*/}
                        {/*                name="name"*/}
                        {/*                value={language.name}*/}
                        {/*                onChange={(e) => handleLanguageChange(index, e)}*/}
                        {/*                label={`Language ${index + 1}`}*/}
                        {/*                options={uniqueLanguages.map((lang) => ({*/}
                        {/*                    value: lang.name,*/}
                        {/*                    label: lang.name,*/}
                        {/*                }))}*/}
                        {/*            />*/}
                        {/*            <button*/}
                        {/*                type="button"*/}
                        {/*                className="button button-border delete-language-button"*/}
                        {/*                onClick={() => handleDeleteLanguage(index)}*/}
                        {/*            >*/}
                        {/*                Delete*/}
                        {/*            </button>*/}
                        {/*        </div>*/}
                        {/*    ))}*/}
                        {/*    {errors.languages && (*/}
                        {/*        <span className="error-message">{errors.languages}</span>*/}
                        {/*    )}*/}
                        {/*    <button*/}
                        {/*        className="underline no-style add-language-button"*/}
                        {/*        type="button"*/}
                        {/*        onClick={handleAddLanguageSelect}*/}
                        {/*    >*/}
                        {/*        Add New Language*/}
                        {/*    </button>*/}
                        {/*</div>*/}

                        <button type="submit" className="button button-black">
                            Add Attorney
                        </button>
                    </form>
                </div>
            </div>
        </div>,
        document.body
    );
}

// Валидация пропсов
AddAttorneyPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    legalCompanyId: PropTypes.string, // Сделано не обязательным, так как может быть null
    onAttorneyAdded: PropTypes.func.isRequired,
    initialBarNumber: PropTypes.string, // Новый проп для передачи номера адвоката
};

// Значение по умолчанию для initialBarNumber и legalCompanyId
AddAttorneyPopup.defaultProps = {
    initialBarNumber: '',
    legalCompanyId: null,
};
