import React, { useState, useEffect, useCallback } from 'react';
import api from '../../api';  // Import API for requests
import './pageAbout.css';
import SimpleMap from '../GoogleMap';
import SliderWithImagesPopup from '../SliderWithImagesPopup';
import Footer from '../Footer/Footer';
import FeedbackPopup from '../popups/FeedbackPopup';
import AdvCampaignPopup from '../popups/AdvCampaignPopup';
import SendOkPopup from "../popups/SendOkPopup";
import Loader from "../Loader";

export default function PageAbout() {
    const [data, setData] = useState(null);
    const [isFeedbackPopupVisible, setIsFeedbackPopupVisible] = useState(false);
    const [isAdvCampaignPopupVisible, setIsAdvCampaignPopupVisible] = useState(false);
    const [isSendOkPopupVisible, setIsSendOkPopupVisible] = useState(false);
    const [listTitle, setListTitle] = useState('');
    const [listDescription, setListDescription] = useState('');
    const [mediaData, setMediaData] = useState([]);  // State for storing media items

    // Fetch data from the server for the "About Us" section
    useEffect(() => {
        api.get('/about-us/')  // Use your API path
            .then(response => {
                setData(response.data);  // Set received data to the state
            })
            .catch(error => {
                console.error('Error fetching about us data:', error);
            });
    }, []);

    // Fetch data for the advertising section
    useEffect(() => {
        api.get('/advertising-sections/', { params: { template_number: 'bio' } })
            .then(response => {
                const section = response.data[0];  // Assuming a single item in the array

                // Construct URLs using the base URL
                const baseUrl = process.env.REACT_APP_BASE_URL.replace(/\/+$/, ''); // Remove trailing slash if any
                const processedMediaItems = section.media_items.map(item => {
                    const url = item.url.replace(/^\/+/, ''); // Remove leading slashes
                    return {
                        type: item.type,
                        src: `${baseUrl}/${url}`  // Construct full URL with base URL
                    };
                });

                setListTitle(section.title);
                setListDescription(section.description);
                setMediaData(processedMediaItems);  // Set processed media items to state
            })
            .catch(error => {
                console.error('Error fetching description:', error);
            });
    }, []);

    const baseUrl = process.env.REACT_APP_BASE_URL.replace(/\/+$/, '');

    const closeFeedbackPopup = useCallback(() => {
        setIsFeedbackPopupVisible(false);
    }, []);

    const showAdvCampaignPopup = (e) => {
        e.preventDefault();
        setIsAdvCampaignPopupVisible(true);
    };

    const closeAdvCampaignPopup = useCallback(() => {
        setIsAdvCampaignPopupVisible(false);
    }, []);

    const handleShowSendOkPopup = useCallback(() => {
        setIsSendOkPopupVisible(true);
        setIsFeedbackPopupVisible(false);
        setIsAdvCampaignPopupVisible(false); // Close AdvCampaignPopup when OK Popup is shown
    }, []);

    const handlerCloseOk = useCallback(() => {
        setIsSendOkPopupVisible(false);
    }, []);

    const handleShowFeedbackPopup = useCallback(() => {
        setIsFeedbackPopupVisible(true);
    }, []);

    if (!data) {
        return <Loader />
    }

    return (
        <>
            <div className='about'>
                <div className='profile__block about__header'>
                    <div className='container'>
                        <h1>{data.title}</h1>  {/* Title from data */}
                    </div>
                    <div className='about__description'>
                        <div className='container'>
                            <p>{data.description_before_picture}</p>  {/* Description before picture */}
                        </div>
                    </div>
                    <div className='about__banner'>
                        <img src={`${baseUrl}${data.picture}`} alt='About Us'/>
                    </div>
                    <div className='container'>
                        <ul className='about__list'>
                            <li>
                                <h2>{data.title_after_picture_1}</h2>  {/* Title after picture */}
                                <p>{data.description_after_picture_1}</p>  {/* Description after picture */}
                            </li>
                            <li>
                                <h2>{data.title_after_picture_2}</h2>
                                <p>{data.description_after_picture_2}</p>
                            </li>
                            <li>
                                <h2>{data.title_after_picture_3}</h2>
                                <p>{data.description_after_picture_3}</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='profile__block'>
                    <div className='container'>
                        <div className='about__mission'>
                            <p>{data.text_before_bold}</p>
                            <h3>{data.bold_text}</h3>
                            <p>{data.text_after_bold}</p>
                        </div>
                    </div>
                </div>

                <div className='profile__block'>
                    <div className='about__content'>
                        <div className='container'>
                            <div className='about__vantages'>
                                <h2>{data.main_title_block_3}</h2>
                                <ul className='about__list'>
                                    <li>
                                        <span className='number'>1</span>
                                        <h3>{data.small_title_1}</h3>
                                        <p>{data.small_description_1}</p>
                                    </li>
                                    <li>
                                        <span className='number'>2</span>
                                        <h3>{data.small_title_2}</h3>
                                        <p>{data.small_description_2}</p>
                                    </li>
                                    <li>
                                        <span className='number'>3</span>
                                        <h3>{data.small_title_3}</h3>
                                        <p>{data.small_description_3}</p>
                                    </li>
                                    <li>
                                        <span className='number'>4</span>
                                        <h3>{data.small_title_4}</h3>
                                        <p>{data.small_description_4}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='about__compaign'>
                            <div className='container'>
                                <div className='text'>
                                    <h2>{listTitle}</h2>
                                    <p>{listDescription}</p>
                                    <a href='' className='button button-black' onClick={showAdvCampaignPopup}>Start Campaign</a>
                                </div>
                                <div className='container'>
                                    <div className='about__offices'>
                                        <SliderWithImagesPopup mediaData={mediaData}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='about__maps'>
                            <div className='container'>
                                <h2>{data.title_block_4}</h2>
                                <ul className='about__list'>
                                    <li>
                                        <h3>{data.address_name_1}</h3>
                                        <p>{data.address_1}</p>
                                        <div className='map'>
                                            <SimpleMap lat={data.lat_1} lng={data.lng_1}/>
                                        </div>
                                    </li>
                                    <li>
                                        <h3>{data.address_name_2}</h3>
                                        <p>{data.address_2}</p>
                                        <div className='map'>
                                            <SimpleMap lat={data.lat_2} lng={data.lng_2}/>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='profile__block'>
                    <div className='container'>
                        <div className='about__mission'>
                            <h2>Do you have any questions?</h2>
                            <p>Feel free to contact us for more information</p>
                            <button className='button button-black' onClick={handleShowFeedbackPopup}>Write now</button>
                        </div>
                    </div>
                </div>

                {isAdvCampaignPopupVisible && (
                    <AdvCampaignPopup
                        show={isAdvCampaignPopupVisible}
                        closeAdvCampaignPopup={closeAdvCampaignPopup}
                        showSendOkPopup={handleShowSendOkPopup}
                    />
                )}

                {isFeedbackPopupVisible && (
                    <FeedbackPopup
                        show={isFeedbackPopupVisible}
                        showSendOkPopup={handleShowSendOkPopup}
                        closeFeedbackPopup={closeFeedbackPopup}
                    />
                )}

                {isSendOkPopupVisible && <SendOkPopup closePopup={handlerCloseOk} />}

                <Footer/>
            </div>
        </>
    );
}
