// src/components/User/ActivateAccount.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import { useNotification } from '../../context/NotificationContext'; // Хук для уведомлений

const ActivateAccount = () => {
    const { uidb64, token } = useParams();
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const activate = async () => {
            try {
                const response = await api.get(`/activate/${uidb64}/${token}/`);
                if (response.status === 200) {
                    showNotification(
                        'Email Confirmed', // Сообщение заголовка на английском
                        'Your email has been successfully confirmed. You can now access all features.', // Сообщение тела на английском
                        null,
                        'success'
                    );
                    navigate('/profile'); // Перенаправляем на профиль или другую страницу
                }
            } catch (error) {
                showNotification(
                    'Activation Failed', // Сообщение заголовка на английском
                    'The activation link is invalid or has expired.', // Сообщение тела на английском
                    null,
                    'error'
                );
                navigate('/sign-up'); // Перенаправление на регистрацию или другую страницу
            } finally {
                setLoading(false);
            }
        };

        activate();
    }, [uidb64, token, navigate, showNotification]);

    return (
        <div className="activation-container">
            {loading ? <p>Confirming your email...</p> : null} {/* Текст на английском */}
        </div>
    );
};

export default ActivateAccount;
