// src/utils/getLogoUrl.js

const getLogoUrl = (logo) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    // Проверяем, является ли logo полным URL
    if (logo && !logo.startsWith('http://') && !logo.startsWith('https://')) {
        const modifiedLogo = `${baseUrl}${logo}`;
        return modifiedLogo;
    }

    // Если logo уже содержит полный URL, возвращаем его без изменений
    return logo;
};

export default getLogoUrl;
