// src/context/NotificationContext.js

import React, { createContext, useState, useContext } from 'react';

// Создаем контекст
const NotificationContext = createContext();

// Создаем провайдер
export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState(null);
    const [notificationDetails, setNotificationDetails] = useState(null);
    const [notificationStrong, setNotificationStrong] = useState(null);
    const [notificationType, setNotificationType] = useState('info'); // Тип: info, success, error, warning

    // Функция для отображения уведомления
    const showNotification = (message, details = null, strongMessage = null, type = 'info') => {
        setNotification(message);
        setNotificationDetails(details);
        setNotificationStrong(strongMessage);
        setNotificationType(type);

        // Автоматическое скрытие уведомления через 15 секунд
        setTimeout(() => {
            hideNotification();
        }, 15000);
    };

    // Функция для скрытия уведомления
    const hideNotification = () => {
        setNotification(null);
        setNotificationDetails(null);
        setNotificationStrong(null);
        setNotificationType('info');
    };

    return (
        <NotificationContext.Provider value={{ notification, notificationDetails, notificationStrong, notificationType, showNotification, hideNotification }}>
            {children}
            {/* Удалили рендеринг уведомления из провайдера */}
        </NotificationContext.Provider>
    );
};

// Хук для удобного использования контекста
export const useNotification = () => {
    return useContext(NotificationContext);
};
