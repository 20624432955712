import React, {useState, useEffect, useCallback} from 'react';
import LawCompanyWithImage from './LawCompanyWithImage';
import LawCompanyAdvertise from "./LawCompanyAdvertise";
import api from '../api';

const VariousCompanyCards = React.memo(({lawCompanies, onShowPopup}) => {
    const [advertise, setAdvertise] = useState(null);
    const [loading, setLoading] = useState(true);
    const companiesPerPage = 7; // Количество компаний на страницу


    useEffect(() => {
        const fetchAdvertise = async () => {
            try {
                const response = await api.get('/advertise/');
                setAdvertise(response.data);
            } catch (error) {
                console.error('Error fetching the advertise data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAdvertise();
    }, []);


    const getPaginatedCompanies = useCallback(() => {
        const paginatedCompanies = [];
        for (let i = 0; i < lawCompanies.length; i += companiesPerPage) {
            const page = lawCompanies.slice(i, i + companiesPerPage);
            paginatedCompanies.push(page);
        }
        return paginatedCompanies;
    }, [lawCompanies]);

    const paginatedCompanies = getPaginatedCompanies();
    // const paginatedCompanies = [lawCompanies]; // Временно убираем пагинацию


    return (
        <>
            {paginatedCompanies.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                    {page.map(company => {
                        const hasActivePlans = company.active_plans && company.active_plans.length > 0;
                        const styleClass = hasActivePlans ? 'vendor-day' : (Math.random() > 0.5 ? 'vendor-verified' : '');

                        return (
                            <LawCompanyWithImage
                                key={company.id}
                                company={company}
                                onShowPopup={onShowPopup}
                                styleClass={styleClass}
                            />
                        );
                    })}
                    {!loading && advertise && <LawCompanyAdvertise key={`ad-${pageIndex}`} advertise={advertise}/>}
                </React.Fragment>
            ))}
        </>
    );
});

export default VariousCompanyCards;
