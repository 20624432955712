import React from 'react';
import GoogleMapReact from 'google-map-react';
import MarkerComponent from './MarkerComponent'; // Убедитесь, что путь верный

export default function SimpleMap({ lat, lng }) {
    const parsedLat = parseFloat(lat);
    const parsedLng = parseFloat(lng);


    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyDP3KoaBgNbHUykxILondDpSVGcsfkx5Sg' }}
            defaultCenter={{
                lat: parsedLat,
                lng: parsedLng
            }}
            defaultZoom={14}
        >
            <MarkerComponent
                lat={parsedLat}
                lng={parsedLng}
            />
        </GoogleMapReact>
    );
}
