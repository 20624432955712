// src/components/User/SignIn.js

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import InputWithLabel from "../Form/Input/InputWithLabel";
import api from '../../api';
import { useNotification } from '../../context/NotificationContext'; // Импортируем хук уведомлений
import { useUser } from '../../context/UserContext'
import './SignIn.css';
import Loader from "../Loader";
import GoogleButton from '../Form/GoogleButton';
import getLogoUrl from '../../utils/getLogoUrl';

const SignIn = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [signInImage, setSignInImage] = useState(''); // Устанавливаем изображение по умолчанию
    const navigate = useNavigate();
    const { showNotification } = useNotification(); // Получаем только функцию showNotification
    const { setAuthTokens } = useUser(); // Получаем функцию setAuthTokens из контекста пользователя

    useEffect(() => {
        // Загрузка случайного изображения входа при монтировании компонента
        const fetchSignInImage = async () => {
            try {
                const response = await api.get('/random-signin-photo/');

                if (response.data.image) {
                    const logoUrl = getLogoUrl(response.data.image);
                    setSignInImage(logoUrl);
                } else {
                    // Если по каким-то причинам нет изображения, используем изображение по умолчанию
                    setSignInImage('/assets/sign-in-image.png');
                }
            } catch (error) {
                console.error('Error fetching sign-in image:', error);
                // Устанавливаем изображение по умолчанию в случае ошибки
                setSignInImage('/assets/sign-in-image.png');
            }
        };

        fetchSignInImage();
    }, []);

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            navigate('/profile'); // Перенаправление на профиль, если пользователь аутентифицирован
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setLoading(true);

        // Валидация полей формы
        if (!formData.email || !formData.password) {
            const newErrors = {
                email: !formData.email ? 'Email is required.' : '',
                password: !formData.password ? 'Password is required.' : ''
            };
            setErrors(newErrors);

            showNotification(
                'Please fill in all required fields.',
                'Make sure to check the correctness of the entered data.',
                null,
                'error'
            );

            setLoading(false);
            return;
        }

        try {
            const response = await api.post('/token/', {
                username: formData.email,
                password: formData.password
            });

            if (response.status === 200) {
                // Сохранение токенов в localStorage
                localStorage.setItem('access_token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);

                // Отображение уведомления об успешном входе
                showNotification(
                    'Login successful.',
                    'Welcome back!',
                    null,
                    'success'
                );

                // Установка токенов в контекст пользователя
                setAuthTokens({
                    access: response.data.access,
                    refresh: response.data.refresh
                });

                // Перенаправление на страницу профиля
                navigate('/profile');
            } else {
                setErrors({
                    email: response.data.detail || 'Invalid credentials.',
                    password: ' '
                });

                // Отображение уведомления о неверных учетных данных
                showNotification(
                    'Invalid credentials. Please try again.',
                    'Check the entered information and try again.',
                    null,
                    'error'
                );
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors({
                    email: error.response.data.detail || 'Something went wrong. Please try again later.',
                    password: ' '
                });

                showNotification(
                    error.response.data.detail || 'Login error. Please try again later.',
                    'Check your entered information or try again later.',
                    null,
                    'error'
                );
            } else {
                setErrors({ email: 'Something went wrong. Please try again later.' });

                showNotification(
                    'Network error. Please check your connection and try again.',
                    'Ensure you have an active internet connection and try again.',
                    null,
                    'error'
                );
            }
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loader />
    }

    return (
        <div className="main-form-container">
            <div
                className="main-form__img"
                style={{
                    backgroundColor: '#fff',
                    backgroundImage: `url(${signInImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                    backgroundSize: 'cover',
                }}
            ></div>

            <div className="main-form__right">
                <form className="main-form__content" onSubmit={handleSubmit}>
                    <div className="scroll-wrap">
                        <h2>Let's get started</h2>
                        <div className="sign-up-link">
                            <span>Don't have an account? </span>
                            <Link to="/sign-up">Sign Up</Link>
                        </div>

                        <InputWithLabel
                            label='E-mail'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            type='email'
                            hasError={!!errors.email} // добавляем пропс для указания ошибки
                        />
                        <InputWithLabel
                            label='Password'
                            name='password'
                            value={formData.password}
                            onChange={handleChange}
                            type='password'
                            hasError={!!errors.password} // добавляем пропс для указания ошибки
                        />
                        <div
                            className="forgot-password-link"
                            style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '15px' }}
                        >
                            <Link to="/forgot-password">Forgot Password?</Link>
                        </div>

                        <button className="button button-black" type="submit" disabled={loading}>
                            {loading ? 'Loading...' : 'Continue'}
                        </button>
                        {/*<button className="button button-border" type="button" disabled={loading}>*/}
                        {/*    <img src="/assets/Apple.svg" alt="Apple Icon" style={{ marginRight: '8px' }} />*/}
                        {/*    Continue with Apple*/}
                        {/*</button>*/}
                        {/* Кастомная кнопка для входа через Google */}
                        <GoogleButton redirectPath="/profile" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
