import React from 'react';
import './InputFile.css';

export default function InputFile({ files, setFiles, removeFile }) {
    const handleMultipleChange = (event) => {
        setFiles([...event.target.files]);
    };

    return (
        <>
            <span>Attachment</span>
            <div className='load-files'>
                {files.length > 0 && (
                    <ul className='files'>
                        {files.map((file, index) => (
                            <li key={`file${index}`} className='file'>
                                <div className='file__icon'>
                                    <img src='/assets/icon--file.svg' alt='download' />
                                </div>
                                <div className='file__content'>
                                    <span className='file__name'>{file.name}</span>
                                    <span className='file__size'>{(file.size / 1024 / 1024).toFixed(2)} Mb</span>
                                </div>
                                <div onClick={() => removeFile(file)} className='file__remove'>
                                    <img src='/assets/icon--delete.svg' alt='delete' />
                                </div>
                            </li>
                        ))}
                    </ul>
                )}

                <label className="input-label">
                    <input
                        type="file"
                        multiple
                        onChange={handleMultipleChange}
                        className="input input-file"
                    />
                    <img src='/assets/icon--download.svg' alt='download' />
                    <span>Add Attachment</span>
                </label>
            </div>
        </>
    );
}
