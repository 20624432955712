import axios from 'axios';

// Создание инстанса axios
const api = axios.create({
    // baseURL: process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/api/', // базовый URL для вашего Django сервера, использующий переменные окружения
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/api/', // базовый URL для вашего Django сервера, использующий переменные окружения
});

// Интерцептор для добавления токена и API ключа в каждый запрос
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        } else {
        }
        // Добавление API ключа в заголовки запросов
        config.headers['X-API-KEY'] = process.env.REACT_APP_API_KEY || '473f9e45eb72ba7960ba6e8bab7accd9382ec10e6261e057b8350de4cf1adfcb';  // Убедитесь, что API ключ правильный
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Логирование ответа
api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.error('Error response:', error);
        if (error.response) {
            console.error('Error response data:', error.response.data);
        }
        return Promise.reject(error);
    }
);

export default api;
