import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputWithLabel from '../Form/Input/InputWithLabel';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals

const EditEducationsPopup = ({ show, onClose, onSave, initialData }) => {
    // Инициализация состояния формы с начальными данными или пустыми полями
    const [formData, setFormData] = useState(() => ({
        educations: initialData?.education?.map(edu => ({
            ...edu,
            institution: edu.institution || '',
            degree: edu.degree || '',
            start_date: edu.start_date || '',
            end_date: edu.end_date || ''
        })) || []
    }));

    // Инициализация состояния для хранения ошибок валидации
    const [errors, setErrors] = useState({});

    // Очистка ошибок при открытии попапа
    useEffect(() => {
        if (show) {
            setErrors({});
        }
    }, [show]);

    // Обработка изменений в полях образования
    const handleEducationChange = (index, name, value) => {
        const updatedEducations = formData.educations.map((edu, i) => {
            if (i === index) {
                return { ...edu, [name]: value };
            }
            return edu;
        });

        setFormData((prevData) => ({
            ...prevData,
            educations: updatedEducations
        }));
    };

    // Добавление нового образования
    const handleAddEducation = () => {
        setFormData((prevData) => ({
            ...prevData,
            educations: [...prevData.educations, {
                institution: '',
                degree: '',
                start_date: '',
                end_date: ''
            }]
        }));
    };

    // Удаление образования по индексу
    const handleDeleteEducation = (index) => {
        const updatedEducations = formData.educations.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            educations: updatedEducations
        }));

        // Удаление ошибок, связанных с удаляемым образованием
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[index];
            return updatedErrors;
        });
    };

    // Функция валидации формы
    const validateForm = () => {
        const newErrors = {};

        formData.educations.forEach((edu, index) => {
            const eduErrors = {};

            // Проверка названия учреждения
            if (!edu.institution || edu.institution.trim() === '') {
                eduErrors.institution = 'Institution name is required.';
            }

            // Проверка степени
            if (!edu.degree || edu.degree.trim() === '') {
                eduErrors.degree = 'Degree is required.';
            }

            // Проверка даты начала
            if (!edu.start_date) {
                eduErrors.start_date = 'Start date is required.';
            } else if (isNaN(Date.parse(edu.start_date))) {
                eduErrors.start_date = 'Start date is invalid.';
            }

            // Проверка даты окончания (если указана)
            if (edu.end_date) {
                if (isNaN(Date.parse(edu.end_date))) {
                    eduErrors.end_date = 'End date is invalid.';
                } else if (edu.start_date && new Date(edu.end_date) < new Date(edu.start_date)) {
                    eduErrors.end_date = 'End date cannot be before start date.';
                }
            }

            // Если есть ошибки в текущем образовании, добавляем их в newErrors
            if (Object.keys(eduErrors).length > 0) {
                newErrors[index] = eduErrors;
            }
        });

        setErrors(newErrors);

        // Возвращаем true, если ошибок нет
        return Object.keys(newErrors).length === 0;
    };

    // Обработка отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        // Фильтрация валидных образовательных записей
        const validEducations = formData.educations.filter(edu => edu.institution.trim() && edu.degree.trim());

        if (validEducations.length) {
            await onSave({ educations: validEducations });
        } else {
            console.error("No valid educations to save");
        }
    };

    // Условный рендеринг попапа
    if (!show) return null;

    return ReactDOM.createPortal(
        <div className="modal visible" id="edit-educations">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal" aria-label="Close Popup">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close" />
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit} noValidate>
                        <h2>Edit Education</h2>
                        {formData.educations.map((edu, index) => (
                            <React.Fragment key={index}>
                                {/* Поле для названия учреждения */}
                                <InputWithLabel
                                    name="institution"
                                    value={edu.institution}
                                    onChange={(e) => handleEducationChange(index, 'institution', e.target.value)}
                                    label="Institution"
                                    error={errors[index]?.institution}
                                />
                                {/* Поле для степени */}
                                <InputWithLabel
                                    name="degree"
                                    value={edu.degree}
                                    onChange={(e) => handleEducationChange(index, 'degree', e.target.value)}
                                    label="Degree"
                                    error={errors[index]?.degree}
                                />
                                {/* Поле для даты начала */}
                                <InputWithLabel
                                    name="start_date"
                                    value={edu.start_date}
                                    onChange={(e) => handleEducationChange(index, 'start_date', e.target.value)}
                                    label="Start Date"
                                    type="date"
                                    error={errors[index]?.start_date}
                                />
                                {/* Поле для даты окончания */}
                                <InputWithLabel
                                    name="end_date"
                                    value={edu.end_date}
                                    onChange={(e) => handleEducationChange(index, 'end_date', e.target.value)}
                                    label="End Date"
                                    type="date"
                                    error={errors[index]?.end_date}
                                />
                                {/* Кнопка удаления образования */}
                                <button
                                    className="button no-style"
                                    type="button"
                                    onClick={() => handleDeleteEducation(index)}
                                >
                                    Delete
                                </button>
                            </React.Fragment>
                        ))}
                        {/* Кнопка добавления нового образования */}
                        <button
                            className="button button-border"
                            type="button"
                            onClick={handleAddEducation}
                        >
                            Add New Education
                        </button>
                        {/* Кнопка отправки формы */}
                        <button className="button button-black" type="submit">Save Education</button>
                    </form>
                </div>
            </div>
        </div>,
        document.body // Портал в body
    );
};

// Определение типов пропсов для лучшей проверки типов
EditEducationsPopup.propTypes = {
    show: PropTypes.bool.isRequired, // Флаг отображения попапа
    onClose: PropTypes.func.isRequired, // Функция для закрытия попапа
    onSave: PropTypes.func.isRequired, // Функция для обработки сохранённых данных
    initialData: PropTypes.shape({
        education: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            institution: PropTypes.string,
            degree: PropTypes.string,
            start_date: PropTypes.string, // Формат даты: YYYY-MM-DD
            end_date: PropTypes.string,   // Формат даты: YYYY-MM-DD
        })),
    }),
};

export default EditEducationsPopup;
