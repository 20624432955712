import React, { useState, useEffect } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import api from '../../api';
import './style.css';

const MultiSelectContactMethods = ({ label, onChange }) => {
    const [contactMethods, setContactMethods] = useState([]);
    const [selectedContactMethods, setSelectedContactMethods] = useState([]);

    useEffect(() => {
        const fetchContactMethods = async () => {
            try {
                const response = await api.get('/contact-methods/');
                setContactMethods(response.data);
            } catch (error) {
                console.error('Error fetching contact methods:', error);
            }
        };

        fetchContactMethods();
    }, []);

    useEffect(() => {
        if (onChange) {
            onChange(selectedContactMethods);
        }
    }, [selectedContactMethods, onChange]);

    return (
        <div className="column">
            <label className="label">{label}</label>
            <MultiSelect
                value={selectedContactMethods}
                options={contactMethods}
                onChange={(e) => setSelectedContactMethods(e.value)}
                optionLabel="name"
                display="chip"
                filter
                className=""
                placeholder=""
            />
        </div>
    );
};

export default MultiSelectContactMethods;
