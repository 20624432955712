const fetchCityByIP = async () => {
    try {
        const response = await fetch(`https://api.ipdata.co?api-key=c827c4a8ebc1404295d745862c91bd8b44ee06d10e622e8e2ec1428c`);
        const data = await response.json();
        if (data && data.city) {
            return data.city;
        } else {
            console.error('Error fetching city by IP:', data);
            return null;
        }
    } catch (error) {
        console.error('Error fetching city by IP:', error);
        return null;
    }
};

export default fetchCityByIP;