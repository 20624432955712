import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation } from 'swiper/modules';
import Modal from 'react-modal';
import './SliderCustom.css';

const SliderWithImagesPopup = ({ mediaData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentMediaIndex, setCurrentMediaIndex] = useState(null);

    const openModal = (index) => {
        setCurrentMediaIndex(index);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setCurrentMediaIndex(null);
    };

    const showPreviousMedia = () => {
        setCurrentMediaIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : mediaData.length - 1));
    };

    const showNextMedia = () => {
        setCurrentMediaIndex((prevIndex) => (prevIndex < mediaData.length - 1 ? prevIndex + 1 : 0));
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (isOpen) {
                if (event.key === 'ArrowLeft') {
                    showPreviousMedia();
                } else if (event.key === 'ArrowRight') {
                    showNextMedia();
                } else if (event.key === 'Escape') {
                    closeModal();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen]);

    const renderMedia = (item) => {
        if (item.type === 'video') {
            return <video src={item.src} autoPlay loop className="modal-video" controls />;
        } else {
            return <img src={item.src} alt="Media preview" className="modal-image" />;
        }
    };

    return (
        <>
            <Swiper
                modules={[Autoplay, Navigation]}
                spaceBetween={30}
                slidesPerView={1}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                breakpoints = {{
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
                navigation={false}
                loop={true}
            >
                {mediaData.map((item, index) => (
                    <SwiperSlide key={index} onClick={() => openModal(index)}>
                        <div className="media-gallery-item">
                            {item.type === 'video' ? (
                                <div className="media-gallery-video-container">
                                    <video src={item.src} className="media-gallery-video" />
                                    <div className="play-icon-overlay">
                                        <img src="/assets/play-icon.svg" alt="Play" className="play-icon" />
                                    </div>
                                </div>
                            ) : (
                                <img src={item.src} alt={`media-${index}`} className="media-gallery-image" />
                            )}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="Media Modal"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <button onClick={closeModal} className="modal__button-close">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close" />
                </button>
                {currentMediaIndex !== null && (
                    <>
                        <button onClick={showPreviousMedia} className="modal__button-nav modal__button-prev">←</button>
                        {renderMedia(mediaData[currentMediaIndex])}
                        <button onClick={showNextMedia} className="modal__button-nav modal__button-next">→</button>
                    </>
                )}
            </Modal>
        </>
    );
};

export default SliderWithImagesPopup;
