import React, {useState, useEffect, useCallback} from 'react';
import InputWithLabel from "../Form/Input/InputWithLabel";
import TextareaWithLabel from "../Form/Input/TextareaWithLabel";
import api from '../../api';
import Cookies from 'js-cookie';
import AddAttorneyPopup from '../popups/AddAttorneysPopup';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals

// Компонент для создания или редактирования профиля юридической компании
const CreateLawCompanyProfile = ({show, onClose, userId, existingProfile}) => {
    const currentYear = new Date().getFullYear();

    // Инициализация состояния формы
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        email: '',
        phone_number: '',
        web_site: '',
        foundation_year: '',
        address: {
            country_id: '1', // По умолчанию США
            state_id: '',
            city_id: '',
            zip_code_id: '',
            street_address: '',
        },
        attorneys: [],
        status: 'inactive', // Начальный статус
    });

    // Состояния для списка штатов, городов и почтовых индексов
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [zipCodes, setZipCodes] = useState([]);

    // Состояния для обработки ошибок и загрузки
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Состояние для списка адвокатов
    const [myAttorneys, setMyAttorneys] = useState([]);
    const [selectedAttorneyIds, setSelectedAttorneyIds] = useState(new Set());

    // Состояния для отображения групп адвокатов
    const [visibleGroups, setVisibleGroups] = useState(1);
    const groupArray = (array, groupSize) => {
        const groups = [];
        for (let i = 0; i < array.length; i += groupSize) {
            groups.push(array.slice(i, i + groupSize));
        }
        return groups;
    };
    const groupedAttorneys = groupArray(myAttorneys, 3);

    // Состояния для всплывающего окна добавления адвоката
    const [isAddAttorneyPopupOpen, setIsAddAttorneyPopupOpen] = useState(false);
    const [currentAttorneyBarNumber, setCurrentAttorneyBarNumber] = useState('');

    // Мемоизированная функция для получения конфигурации запроса с CSRF и токеном
    const getRequestConfig = useCallback(() => {
        const token = localStorage.getItem('authToken');
        const csrfToken = Cookies.get('csrftoken');

        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true,
        };
    }, []);

    // Функции для загрузки данных (штаты, города, почтовые индексы)
    const fetchStates = useCallback(async (country_id) => {
        try {
            const response = await api.get(`/states-filtered/?country_id=${country_id}`, getRequestConfig());
            setStates(response.data);
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    }, [getRequestConfig]);

    const fetchCities = useCallback(async (state_id) => {
        try {
            const response = await api.get(`/cities-filtered/?state_id=${state_id}`, getRequestConfig());
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    }, [getRequestConfig]);

    const fetchZipCodes = useCallback(async (city_id) => {
        try {
            const response = await api.get(`/zip-codes/?city_id=${city_id}`, getRequestConfig());
            setZipCodes(response.data);
        } catch (error) {
            console.error('Error fetching zip codes:', error);
        }
    }, [getRequestConfig]);

    // Получение штатов при монтировании компонента
    useEffect(() => {
        fetchStates(formData.address.country_id); // Используем country_id из formData
    }, [fetchStates, formData.address.country_id]);

    // Обработка изменений в основных полях формы
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Обработка изменений в полях адреса
    const handleAddressChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            address: {
                ...prevData.address,
                [name]: value
            }
        }));
    };

    // Загрузка городов при изменении штата
    useEffect(() => {
        if (formData.address.state_id) {
            fetchCities(formData.address.state_id);
        } else {
            setCities([]);
            setFormData(prevData => ({
                ...prevData,
                address: {
                    ...prevData.address,
                    city_id: '',
                    zip_code_id: '',
                    street_address: '',
                },
            }));
        }
    }, [formData.address.state_id, fetchCities]);

    // Загрузка почтовых индексов при изменении города
    useEffect(() => {
        if (formData.address.city_id) {
            fetchZipCodes(formData.address.city_id);
        } else {
            setZipCodes([]);
            setFormData(prevData => ({
                ...prevData,
                address: {
                    ...prevData.address,
                    zip_code_id: '',
                    street_address: '',
                },
            }));
        }
    }, [formData.address.city_id, fetchZipCodes]);

    // Установка данных формы при наличии существующего профиля
    useEffect(() => {
        if (existingProfile) {
            const updatedFormData = {
                name: existingProfile.name || '',
                description: existingProfile.description || '',
                email: existingProfile.email || '',
                phone_number: existingProfile.phone_number || '',
                web_site: existingProfile.web_site || '',
                foundation_year: existingProfile.foundation_year || '',
                address: {
                    country_id: existingProfile.address?.country?.country_id ? String(existingProfile.address.country.country_id) : '1',
                    state_id: existingProfile.address?.state?.state_id ? String(existingProfile.address.state.state_id) : '',
                    city_id: existingProfile.address?.city?.city_id ? String(existingProfile.address.city.city_id) : '',
                    zip_code_id: existingProfile.address?.zip_code?.id ? String(existingProfile.address.zip_code.id) : '',
                    street_address: existingProfile.address?.street_address || '',
                },
                attorneys: existingProfile.attorneys || [],
                status: existingProfile.status || 'inactive',
            };

            setFormData(updatedFormData);

            // Устанавливаем выбранных адвокатов
            const attorneyIds = Array.isArray(existingProfile.attorneys)
                ? existingProfile.attorneys.map(attorney => attorney.attorney_id)
                : [];
            setSelectedAttorneyIds(new Set(attorneyIds));

            // Загружаем штаты для выбранной страны
            fetchStates(updatedFormData.address.country_id);

            // Загружаем города и почтовые индексы
            if (updatedFormData.address.state_id) {
                fetchCities(updatedFormData.address.state_id);
            }

            if (updatedFormData.address.city_id) {
                fetchZipCodes(updatedFormData.address.city_id);
            }
        }
    }, [existingProfile, fetchStates, fetchCities, fetchZipCodes]);

    // Функция для загрузки всех адвокатов без учета пагинации
    const fetchAllAttorneys = useCallback(async () => {
        setIsLoading(true);
        let allAttorneys = [];
        let nextPageUrl = `/user-attorneys/?page=1`; // Начальная страница

        try {
            while (nextPageUrl) {
                const response = await api.get(nextPageUrl, getRequestConfig());
                const data = response.data;
                allAttorneys = [...allAttorneys, ...data.results];
                nextPageUrl = data.next ? data.next.replace('https://law.law/api', '') : null; // Убираем базовый URL, если требуется
            }
            // Сортировка адвокатов в обратном порядке по attorney_id
            allAttorneys.sort((a, b) => b.attorney_id - a.attorney_id);
            setMyAttorneys(allAttorneys);
        } catch (error) {
            console.error('Error fetching attorney companies:', error.response ? error.response.data : error.message);
        } finally {
            setIsLoading(false);
        }
    }, [getRequestConfig]);

    // Получение списка адвокатов при монтировании компонента
    useEffect(() => {
        fetchAllAttorneys();
    }, [fetchAllAttorneys]);

    // Обработка изменения чекбоксов для выбора адвокатов
    const handleCheckboxChange = (attorneyId) => {
        setSelectedAttorneyIds((prevSelected) => {
            const updatedSelected = new Set(prevSelected);
            if (updatedSelected.has(attorneyId)) {
                updatedSelected.delete(attorneyId);
            } else {
                updatedSelected.add(attorneyId);
            }
            return updatedSelected;
        });
    };

    // Валидация данных формы
    const validateForm = useCallback(() => {
        const newErrors = {};

        // Валидация имени компании
        if (!formData.name.trim()) {
            newErrors.name = 'Company name is required.';
        }

        // Валидация email
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required.';
        } else {
            // Простая проверка формата email
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                newErrors.email = 'Invalid email format.';
            }
        }

        // Валидация номера телефона
        if (!formData.phone_number.trim()) {
            newErrors.phone_number = 'Phone number is required.';
        }

        // Валидация года основания
        if (!formData.foundation_year.trim()) {
            newErrors.foundation_year = 'Foundation year is required.';
        }

        // Валидация описания
        if (formData.description.trim().length < 300) {
            newErrors.description = 'Description must be at least 300 characters.';
        }

        // Валидация веб-сайта
        const domainContainsDotRegex = /\..+$/;
        if (formData.web_site && !domainContainsDotRegex.test(formData.web_site)) {
            newErrors.web_site = 'Website must contain a dot followed by a domain, like ".com".';
        }

        // Валидация адреса
        const addressErrors = {};

        if (!formData.address.state_id) {
            addressErrors.state_id = 'State is required.';
        }

        if (!formData.address.city_id) {
            addressErrors.city_id = 'City is required.';
        }

        if (!formData.address.zip_code_id) {
            addressErrors.zip_code_id = 'Zip Code is required.';
        }

        if (!formData.address.street_address.trim()) {
            newErrors.street_address = 'Street and House Number is required.';
        }

        if (Object.keys(addressErrors).length > 0) {
            newErrors.address = addressErrors;
        }

        // Валидация адвокатов: должен быть выбран хотя бы один
        if (selectedAttorneyIds.size === 0) {
            newErrors.attorneys = 'At least one attorney must be selected.';
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
        }

        return Object.keys(newErrors).length === 0;
    }, [formData, selectedAttorneyIds]); // Добавлено selectedAttorneyIds

    // Функция для сохранения черновика
    const handleSaveChanges = async () => {

        // Устанавливаем статус как 'inactive'
        const draftData = {...formData, status: 'inactive'};

        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('name', draftData.name);
        formDataToSend.append('description', draftData.description);
        formDataToSend.append('email', draftData.email);
        formDataToSend.append('phone_number', draftData.phone_number);
        formDataToSend.append('web_site', draftData.web_site);
        formDataToSend.append('foundation_year', draftData.foundation_year);
        formDataToSend.append('status', draftData.status); // Status as 'inactive'
        formDataToSend.append('user_id', userId);

        // Добавляем адрес как строку JSON
        formDataToSend.append('address', JSON.stringify(draftData.address));

        // Добавляем выбранных адвокатов (только ID)
        selectedAttorneyIds.forEach(attorney_id => {
            formDataToSend.append('attorneys', attorney_id);
        });

        try {
            const config = getRequestConfig();
            let response;
            if (existingProfile && existingProfile.id) {
                // Обновляем существующий профиль
                response = await api.patch(`/legal-companies-draft/${existingProfile.id}/update/`, formDataToSend, config);
            } else {
                // Создаем новый профиль
                response = await api.post('/legal-companies/create/', formDataToSend, config);
            }

            if (response.status === 200 || response.status === 201) {
                onClose();
                // Опционально: обновите список профилей или покажите уведомление
            } else {
                console.error('Error saving legal company:', response);
                setErrors({general: 'Error saving legal company. Please try again.'});
            }
        } catch (error) {
            console.error('Error saving legal company:', error.response ? error.response.data : error.message);
            setErrors({general: 'An unexpected error occurred. Please try again.'});
        } finally {
            setIsLoading(false);
        }
    };

    // Функция для отправки на утверждение
    const handleSendToApproval = async () => {

        // Устанавливаем статус как 'pending'
        const approvalData = {...formData, status: 'pending'};

        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('name', approvalData.name);
        formDataToSend.append('description', approvalData.description);
        formDataToSend.append('email', approvalData.email);
        formDataToSend.append('phone_number', approvalData.phone_number);
        formDataToSend.append('web_site', approvalData.web_site);
        formDataToSend.append('foundation_year', approvalData.foundation_year);
        formDataToSend.append('status', approvalData.status); // Status as 'pending'
        formDataToSend.append('user_id', userId);

        // Добавляем адрес как строку JSON
        formDataToSend.append('address', JSON.stringify(approvalData.address));

        // Добавляем выбранных адвокатов (только ID)
        selectedAttorneyIds.forEach(attorney_id => {
            formDataToSend.append('attorneys', attorney_id);
        });

        try {
            const config = getRequestConfig();
            let response;
            if (existingProfile && existingProfile.id) {
                // Обновляем существующий профиль
                response = await api.patch(`/legal-companies-draft/${existingProfile.id}/update/`, formDataToSend, config);
            } else {
                // Создаем новый профиль
                response = await api.post('/legal-companies/create/', formDataToSend, config);
            }

            if (response.status === 200 || response.status === 201) {
                onClose();
                // Опционально: обновите список профилей или покажите уведомление
            } else {
                console.error('Error sending legal company for approval:', response);
                setErrors({general: 'Error sending legal company for approval. Please try again.'});
            }
        } catch (error) {
            console.error('Error sending legal company for approval:', error.response ? error.response.data : error.message);
            setErrors({general: 'An unexpected error occurred. Please try again.'});
        } finally {
            setIsLoading(false);
        }
    };

    // Открытие всплывающего окна добавления адвоката
    const openAddAttorneyPopup = () => {
        setIsAddAttorneyPopupOpen(true);
    };

    // Закрытие всплывающего окна добавления адвоката и обновление списка адвокатов
    const closeAddAttorneyPopup = () => {
        setIsAddAttorneyPopupOpen(false);
        setCurrentAttorneyBarNumber(''); // Очистка номера адвоката после закрытия
        fetchAllAttorneys(); // Обновляем список адвокатов
    };

    // Обработчик после успешного добавления адвоката
    const handleAttorneyAdded = async (newAttorney) => {
        try {
            const config = getRequestConfig();
            const response = await api.post('/attorneys/create/', newAttorney, config); // Предполагаемый эндпоинт

            if (response.status === 201) {
                const savedAttorney = response.data;
                setFormData((prevData) => ({
                    ...prevData,
                    attorneys: [...prevData.attorneys, savedAttorney],
                }));
                setMyAttorneys((prevAttorneys) => [savedAttorney, ...prevAttorneys]); // Добавляем в начало для сортировки
            } else {
                console.error('Ошибка при добавлении адвоката:', response);
                setErrors({general: 'Error adding attorney. Please try again.'});
            }
        } catch (error) {
            console.error('Ошибка при добавлении адвоката:', error.response ? error.response.data : error.message);
            setErrors({general: 'An unexpected error occurred while adding attorney. Please try again.'});
        }
    };

    // Функция для отображения дополнительных групп адвокатов
    const handleShowMore = () => {
        setVisibleGroups(prev => prev + 1);
    };

    // Предотвращение отправки формы при нажатии клавиши Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    // Рендеринг компонента
    if (!show) return null;

    return ReactDOM.createPortal(
        <>
            {show && (
                <div className="modal visible" id="create-law-company-profile">
                    <div className="modal__overlay" onClick={onClose}></div>
                    <div className="modal__content">
                        <button onClick={onClose} className="button no-style close js--close-modal">
                            <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                        </button>
                        <div className="modal__form">
                            <form className="content" onSubmit={(e) => { e.preventDefault(); /* Обработчик submit, если необходимо */ }} onKeyDown={handleKeyDown}>
                                <h2>{existingProfile ? 'Create Law Firm Profile' : 'Create Law Firm Profile'}</h2>

                                {/* Общие ошибки */}
                                {errors.general && (
                                    <div className="error-message">
                                        {errors.general}
                                    </div>
                                )}

                                {/* Поле Название компании */}
                                <InputWithLabel
                                    label="Company Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    error={errors.name}
                                />

                                {/* Поле Описание */}
                                <TextareaWithLabel
                                    label="Description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    error={errors.description}
                                />

                                {/* Поле Email */}
                                <InputWithLabel
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    error={errors.email}
                                />

                                {/* Поле Номер телефона */}
                                <InputWithLabel
                                    label="Phone Number"
                                    name="phone_number"
                                    type="tel"
                                    value={formData.phone_number}
                                    onChange={handleChange}
                                    error={errors.phone_number}
                                />

                                {/* Поле Веб-сайт */}
                                <InputWithLabel
                                    label="Website"
                                    name="web_site"
                                    // type='url'
                                    value={formData.web_site}
                                    onChange={handleChange}
                                    error={errors.web_site}
                                />

                                {/* Поле Год основания */}
                                <InputWithLabel
                                    label="Foundation Year"
                                    name="foundation_year"
                                    type="number"
                                    value={formData.foundation_year}
                                    onChange={handleChange}
                                    min="1800"
                                    max={currentYear}
                                    error={errors.foundation_year} // Передача ошибки
                                />

                                <span>Address</span>

                                {/* Поле Штат */}
                                <select
                                    className={`input ${errors.address?.state_id ? 'input-error' : ''}`}
                                    name="state_id"
                                    value={formData.address.state_id}
                                    onChange={(e) => handleAddressChange('state_id', e.target.value)}
                                >
                                    <option value="" disabled>Select State</option>
                                    {states.map((state) => (
                                        <option key={state.state_id} value={state.state_id}>
                                            {state.name}
                                        </option>
                                    ))}
                                </select>
                                {errors.address?.state_id && (
                                    <span className="error-text">{errors.address.state_id}</span>
                                )}

                                {/* Поле Город */}
                                {formData.address.state_id && (
                                    <select
                                        className={`input ${errors.address?.city_id ? 'input-error' : ''}`}
                                        name="city_id"
                                        value={formData.address.city_id}
                                        onChange={(e) => handleAddressChange('city_id', e.target.value)}
                                    >
                                        <option value="" disabled>Select City</option>
                                        {cities.map((city) => (
                                            <option key={city.city_id} value={city.city_id}>
                                                {city.name}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {formData.address.state_id && errors.address?.city_id && (
                                    <span className="error-text">{errors.address.city_id}</span>
                                )}

                                {/* Поле Почтовый индекс */}
                                {formData.address.city_id && (
                                    <select
                                        className={`input ${errors.address?.zip_code_id ? 'input-error' : ''}`}
                                        name="zip_code_id"
                                        value={formData.address.zip_code_id}
                                        onChange={(e) => handleAddressChange('zip_code_id', e.target.value)}
                                    >
                                        <option value="" disabled>Select Zip Code</option>
                                        {zipCodes.map((zip) => (
                                            <option key={zip.id} value={zip.id}>
                                                {zip.zip_code}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {formData.address.city_id && errors.address?.zip_code_id && (
                                    <span className="error-text">{errors.address.zip_code_id}</span>
                                )}

                                {/* Поле Улица и номер дома */}
                                {formData.address.zip_code_id && (
                                    <InputWithLabel
                                        label="Street Address"
                                        name="street_address"
                                        value={formData.address.street_address}
                                        onChange={(e) => handleAddressChange('street_address', e.target.value)}
                                        error={errors.street_address}
                                    />
                                )}

                                {/* Поле Добавить адвоката */}
                                <label className="input-label input-attorney">
                                    <input
                                        className={`input ${currentAttorneyBarNumber ? 'filled' : ''}`}
                                        type="text"
                                        name="currentAttorneyBarNumber"
                                        value={currentAttorneyBarNumber}
                                        onChange={(e) => setCurrentAttorneyBarNumber(e.target.value)}
                                        placeholder="Attorney License Number"
                                    />
                                    <button
                                        type="button"
                                        className="button button-border"
                                        onClick={openAddAttorneyPopup}
                                    >
                                        Add
                                    </button>
                                </label>

                                {/* Отображение списка адвокатов */}
                                <div className="attorneys-list">
                                    <h3>Attorneys List:</h3>
                                    {myAttorneys.length === 0 && !isLoading && (
                                        <p>No attorneys available.</p>
                                    )}
                                    {isLoading && (
                                        <p>Loading attorneys...</p>
                                    )}
                                    {!isLoading && myAttorneys.length > 0 && (
                                        <>
                                            {groupedAttorneys.slice(0, visibleGroups).map((group, groupIndex) => (
                                                <ul className="attorneys__list" key={`group-${groupIndex}`}>
                                                    {group.map((attorney) => (
                                                        attorney.attorney_id ? (
                                                            <li className="attorney" key={attorney.attorney_id}>
                                                                <div className="attorney__avatar">
                                                                    <div className="avatar">
                                                                        <picture>
                                                                            <img
                                                                                src={attorney.photo}
                                                                                alt={`${attorney.first_name} ${attorney.last_name}`}
                                                                                className="attorney-photo"
                                                                                loading="lazy"
                                                                            />
                                                                        </picture>
                                                                    </div>
                                                                </div>
                                                                <div className="attorney__content">
                                                                    <div>
                                                                        <h3 className="attorney__name">{`${attorney.first_name} ${attorney.last_name}`}</h3>
                                                                    </div>
                                                                    <ul className="params__list">
                                                                        {attorney.specializations && attorney.specializations.slice(0, 3).map((spec) => (
                                                                            <li key={spec.specialization_id}>{spec.name}</li>
                                                                        ))}
                                                                        {attorney.specializations && attorney.specializations.length > 3 && (
                                                                            <li>+{attorney.specializations.length - 3} more</li>
                                                                        )}
                                                                    </ul>
                                                                </div>

                                                                {/* Кастомный чекбокс */}
                                                                <label className="label-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedAttorneyIds.has(attorney.attorney_id)}
                                                                        onChange={() => handleCheckboxChange(attorney.attorney_id)}
                                                                    />
                                                                    <div className="psevdocheckbox"></div>
                                                                </label>
                                                            </li>
                                                        ) : null
                                                    ))}
                                                </ul>
                                            ))}
                                            {visibleGroups < groupedAttorneys.length && (
                                                <button
                                                    type="button"
                                                    className="button button-border show-more-button"
                                                    onClick={handleShowMore}
                                                >
                                                    More
                                                </button>
                                            )}
                                        </>
                                    )}
                                    {/* Отображение ошибки, если ни один адвокат не выбран */}
                                    {errors.attorneys && (
                                        <span className="error-message">{errors.attorneys}</span> // Используем 'error-text'
                                    )}
                                </div>

                                {/* Кнопки отправки формы */}
                                <div className="button-row">
                                    <button
                                        className="button button-border save-draft-button"
                                        type="button"
                                        onClick={handleSaveChanges}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Saving...' : 'Save Draft'}
                                    </button>
                                    <button
                                        className="button button-black create-profile-button"
                                        type="button"
                                        onClick={handleSendToApproval}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Sending...' : 'Send for Approval'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                )}
                {/* Всплывающее окно для добавления адвоката */}
                <AddAttorneyPopup
                    show={isAddAttorneyPopupOpen}
                    onClose={closeAddAttorneyPopup}
                    legalCompanyId={existingProfile ? existingProfile.id : null}
                    onAttorneyAdded={handleAttorneyAdded}
                    initialBarNumber={currentAttorneyBarNumber}
                />
            </>,
        document.body // Портал в body
        );

};

export default CreateLawCompanyProfile;
